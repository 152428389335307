import { fontS, Theme } from 'components/styles';
import Palette from 'components/styles/colors';
import { css, FlattenInterpolation, ThemeProps } from 'styled-components';

export type TagVariant =
  | 'admin'
  | 'dark'
  | 'earnCash'
  | 'featuredPrize'
  | 'featuredTournament'
  | 'full'
  | 'fullFeatured'
  | 'gameLabel'
  | 'joined'
  | 'light'
  | 'plPlusLabel'
  | 'plPlusOnly'
  | 'popular'
  | 'tournamentJoined'
  | 'unverified'
  | 'winner'
  | 'live'
  | 'won'
  | 'lost';

const tagVariants = {
  winner: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.new.success.text};
    background-color: ${({ theme }) => theme.new.success.background};
  `,
  admin: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.new.information.text};
    background-color: ${({ theme }) => theme.new.information.background};
  `,
  light: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.new.subContent};
    background-color: ${({ theme }) => theme.new.surface};
    border: 1px solid ${({ theme }) => theme.new.divisions};
    display: inline-block;
  `,
  dark: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.new.surface};
    background-color: ${({ theme }) => theme.new.subContent};
    display: inline-block;
  `,
  earnCash: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.new.purplePayout.text};
    background-color: ${({ theme }) => theme.new.purplePayout.background};
    display: inline-block;
  `,
  full: ({
    customTextColor,
  }: {
    customTextColor?: string;
  }): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${customTextColor};
    background-color: ${({ theme }) => theme.new.surface};
  `,
  gameLabel: ({
    tagColor,
  }: {
    tagColor?: string;
  }): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.new.information.text};
    background-color: ${({ theme }) =>
      tagColor || theme.new.unrated.background};
  `,
  fullFeatured: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.new.error.text};
    background-color: ${({ theme }) => theme.new.error.background};
    ${fontS};
  `,
  featuredPrize: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.new.surface};
    background-color: ${({ theme }) => theme.new.content};
  `,
  joined: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.new.success.text};
    background-color: ${({ theme }) => theme.new.success.background};
  `,
  tournamentJoined: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.new.content};
    background-color: ${({ theme }) => theme.new.divisions};
    ${fontS}
  `,
  popular: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.new.purplePayout.background};
    background-color: ${({ theme }) => theme.new.purplePayout.text};
  `,
  unverified: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.new.warning.text};
    background-color: ${({ theme }) => theme.new.warning.background};
  `,
  plPlusOnly: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.new.subContent};
    background-color: ${({ theme }) => theme.new.surface};
    border: 1px solid ${({ theme }) => theme.new.divisions};
  `,
  featuredTournament: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.new.subContent};
    background-color: ${({ theme }) => theme.new.surface};
    border: 1px solid ${({ theme }) => theme.new.goldPayout.background};
  `,
  live: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.new.success.text};
    background-color: ${({ theme }) => theme.new.success.background};
  `,
  won: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.new.goldPayout.text};
    background-color: ${({ theme }) => theme.new.goldPayout.background};
  `,
  lost: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.new.information.text};
    background-color: ${({ theme }) => theme.new.information.background};
  `,
  plPlusLabel: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    background: linear-gradient(
      45.18deg,
      #72042c 0%,
      #ff6f42 46.88%,
      #ffd541 100%
    );
    color: ${({ theme }) => theme.new.information.text};
  `,
  twitch: (): FlattenInterpolation<ThemeProps<Theme>> => css`
    color: ${({ theme }) => theme.new.information.text};
    background-color: ${Palette.twitch};
  `,
};

export const getTagVariant = (
  tag: TagVariant,
  customTextColor?: string,
  tagColor?: string,
): FlattenInterpolation<ThemeProps<Theme>> => {
  if (!tagVariants[tag]) return tagVariants.light();
  return tagVariants[tag]({ customTextColor, tagColor });
};
