import React from 'react';
import { MatchContestFragment } from 'graphpl/core';
import { addCommasToNumber } from 'components/util/add-commas-to-number';
import {
  StyledSurface,
  Rule,
  RuleDescription,
  RuleTitle,
  RulesWrapper,
  SectionHeading,
} from './match-details-section.styles';

type MatchDetailsSectionProps = {
  matchData: MatchContestFragment;
};

type DetailsType = {
  title: string;
  description: string;
};

const constructDetails = (matchData: MatchContestFragment): DetailsType[] => {
  const details: DetailsType[] = [];

  const amountPrefix = matchData?.amountMin ? 'Max ' : '';

  if (matchData?.amount?.value) {
    details.push({
      title: `${amountPrefix}Prize`,
      description: `$${addCommasToNumber(matchData.amount.value * 2)}`,
    });
    details.push({
      title: `${amountPrefix}Entry Fee`,
      description: `$${addCommasToNumber(matchData.amount.value)}`,
    });
  }

  if (matchData?.consoleSetting?.displayName) {
    details.push({
      title: 'Creators Platform',
      description: matchData.consoleSetting.displayName,
    });
  }
  if (
    matchData?.allowedGameFormats &&
    matchData?.allowedGameFormats?.length > 1
  ) {
    details.push({
      title: 'Match Formats',
      description: 'Multiple Formats',
    });
  }
  if (
    matchData?.allowedGameFormats &&
    matchData?.allowedGameFormats?.length === 1
  ) {
    if (matchData?.allowedGameFormats[0]?.name) {
      details.push({
        title: 'Match Formats',
        description: matchData?.allowedGameFormats[0]?.name,
      });
    }
    if (!matchData?.rules) {
      matchData?.allowedGameFormats[0]?.descriptors?.forEach(
        (descriptor, index) => {
          if (descriptor) {
            details.push({
              title: `Rule ${index + 1}`,
              description: descriptor,
            });
          }
        },
      );
    }
  }

  matchData?.rules?.forEach((rule) => {
    if (!rule || !rule.optionName || !rule.valueName) return;
    details.push({
      title: rule.optionName,
      description: rule.valueName,
    });
  });

  if (matchData?.description) {
    details.push({
      title: 'Additional information',
      description: matchData.description,
    });
  }

  return details;
};

export const MatchDetailsSection = ({
  matchData,
}: MatchDetailsSectionProps) => {
  const details = constructDetails(matchData);
  return (
    <StyledSurface size="large" includePadding>
      <SectionHeading>Match details</SectionHeading>
      <RulesWrapper>
        {details.map((rule) => {
          return (
            <Rule key={`${rule.title}-${rule.description}`}>
              <RuleDescription>{rule?.description}</RuleDescription>
              <RuleTitle>{rule?.title}</RuleTitle>
            </Rule>
          );
        })}
      </RulesWrapper>
    </StyledSurface>
  );
};
