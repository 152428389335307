import React from 'react';
import Switch from 'react-switch';
import styled, { useTheme } from 'styled-components';
import {
  fireButtonAnalytics,
  CLICK_BUTTON,
  GLOBAL_HEADER_TOGGLE_DOWN_TO_PLAY,
} from 'analytics';
import { useToast } from 'components/util/toast-context';
import { useUser } from 'components/util/user-context';
import { T } from 'react-polyglot-hooks';
import { useSetIsDownToPlayMutation } from 'graphpl/core';
import { fontL, TOUCHABLE_STYLE } from 'components/styles';
import { parseToRgb, rgb } from 'polished';

const Header = styled.h2`
  ${fontL};
  color: ${({ theme }) => theme.new.content};
  display: flex;
  align-items: center;
`;

const DownToPlayWrapper = styled.div<{ hugLeft: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ hugLeft, theme }) =>
    hugLeft &&
    `
    justify-content: flex-start;
    gap: ${theme.spacing.BASE_SPACING};
  `}
`;
const SwitchBorder = styled.div`
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.new.divisions};
  border-radius: 50px;
  height: 24px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${TOUCHABLE_STYLE}
`;

const OpenToChallenges = ({
  storybookDisabled,
  hugLeft = false,
}: {
  storybookDisabled?: boolean;
  hugLeft?: boolean;
}) => {
  const { displayToast } = useToast();
  const theme = useTheme();
  const { user, setUserRaw } = useUser();

  const [setIsDownToPlay, { loading }] = useSetIsDownToPlayMutation({
    onCompleted: (data) => {
      const downToPlay = data?.setDownToPlay?.isDownToPlay || false;
      setUserRaw((prev) => {
        if (!prev) return prev;
        return { ...user, isDownToPlay: downToPlay };
      });
      displayToast({
        toastDisplayed: true,
        type: downToPlay ? 'success' : 'info',
        subtext: (
          <T
            phrase={
              downToPlay
                ? 'downToPlay.onSuccessBody'
                : 'downToPlay.offSuccessBody'
            }
          />
        ),
        heading: (
          <T
            phrase={
              downToPlay
                ? 'downToPlay.onSuccessHeader'
                : 'downToPlay.offSuccessHeader'
            }
          />
        ),
      });
    },
    onError: () => {
      displayToast({
        toastDisplayed: true,
        type: 'error',
        subtext: <T phrase="downToPlay.error" />,
      });
    },
  });
  const updateIsDownToPlay = async (value: boolean) => {
    fireButtonAnalytics({
      event: CLICK_BUTTON,
      source: GLOBAL_HEADER_TOGGLE_DOWN_TO_PLAY,
      state: {
        value,
      },
    });

    setIsDownToPlay({
      variables: {
        enabled: value,
      },
    });
  };

  const buttonDisabled = loading || storybookDisabled;

  return (
    <DownToPlayWrapper hugLeft={hugLeft}>
      <Header>
        <T phrase="openToChallenges" />
      </Header>
      <SwitchBorder>
        <Switch
          id="dtp-menu-toggle"
          onChange={updateIsDownToPlay}
          checked={user?.isDownToPlay || false}
          disabled={buttonDisabled}
          checkedIcon={false}
          offColor={rgb(parseToRgb(theme.new.background))}
          onColor={rgb(parseToRgb(theme.new.information.background))}
          uncheckedIcon={false}
          handleDiameter={16}
          height={22}
          width={38}
          data-testid="dtp-menu-toggle-checkbox"
          activeBoxShadow={undefined}
        />
      </SwitchBorder>
    </DownToPlayWrapper>
  );
};

export default OpenToChallenges;
