import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import LoadingSpinner from 'components/atoms/loading-spinner';
import { useUser } from 'components/util/user-context';
import { LoadingContainer } from './chat.styles';
import { ChannelTypes } from './components/custom-message/custom-message';

const HIDDEN_CHAT_TIMEOUT_MS = 30000;

const DynamicChat = dynamic(() => import('./chat.dynamic'), {
  ssr: false,
  loading: () => (
    <LoadingContainer data-testid="chat-loading">
      <LoadingSpinner size={32} />
    </LoadingContainer>
  ),
});

type ChatProps = {
  isOpen?: boolean;
  hiddenChatTimeoutMs?: number;
  channelName: string;
  channelType: ChannelTypes;
  gameSeriesId?: string;
};

const Chat = ({ hiddenChatTimeoutMs, isOpen, ...rest }: ChatProps) => {
  const { user } = useUser();

  const [open, setOpen] = useState(false);

  /**
   * We want to keep the chat live for a while even
   * after its panel is closed, in case the user opens
   * it back up.
   */
  useEffect(() => {
    if (isOpen) setOpen(true);

    const handler = setTimeout(() => {
      if (!isOpen) setOpen(false);
    }, hiddenChatTimeoutMs || HIDDEN_CHAT_TIMEOUT_MS);

    return () => clearTimeout(handler);
  }, [hiddenChatTimeoutMs, isOpen, setOpen]);

  const token = user?.streamToken || null;

  if (!token || !(open || isOpen)) {
    return <div data-testid="empty-chat" />;
  }

  return <DynamicChat userToken={token} {...rest} />;
};

export default Chat;
