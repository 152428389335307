/* eslint-disable unicorn/filename-case */
import React, { useEffect } from 'react';
import Head from 'next/head';
import {
  getDefaultProps,
  addApolloState,
  initializeApollo,
  subscribeToChannel,
  bindChannel,
  unbindChannel,
  ON_SERVER,
  initPusherSocket,
} from 'helpers';
import { GetServerSideProps } from 'next';
import {
  ContestStatus,
  GetMatchDocument,
  GetMatchQuery,
  GetMatchQueryVariables,
  UserFragment,
  useGetMatchQuery,
} from 'graphpl/core';
import styled from 'styled-components';
import { MatchScreen } from 'components/organisms/match-screen';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { useRouter } from 'next/router';
import { messageParent } from 'helpers/web-view';

type MatchPageProps = {
  authenticated: boolean;
  matchId: string;
  topPadding?: number;
  user: UserFragment | null;
  matchData: GetMatchQuery | null;
  matchErrors: readonly GraphQLError[] | null;
};

const PageWrapper = styled.div<{ topPadding?: number }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${({ topPadding }) => topPadding || 0}px;
  background-color: ${({ theme }) => theme.new.background};
`;

export const isValidToShowNewMatchPage = (
  status: ContestStatus | null | undefined,
) => {
  if (!status) return false;
  if (status === ContestStatus.PENDING) return true;
  if (status === ContestStatus.OPEN) return true;
  if (status === ContestStatus.INVITED) return true;
  if (status === ContestStatus.MATCHED) return false;
  return false;
};

const LoungeWrapper = ({
  authenticated,
  matchId,
  topPadding,
  matchData,
  matchErrors,
}: MatchPageProps) => {
  const router = useRouter();

  const { data, error, refetch } = useGetMatchQuery({
    variables: {
      matchId,
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    pollInterval: 15 * 1000,
    skip: ON_SERVER,
  });

  const useServerData = matchId === matchData?.contest?.id && !data;
  const determinedMatchData = useServerData
    ? matchData?.contest
    : data?.contest;

  const status = determinedMatchData?.status;

  useEffect(() => {
    if (!determinedMatchData?.id) return;

    initPusherSocket();

    const channelName = `match-${determinedMatchData.id}`;
    const channel = subscribeToChannel(channelName);

    if (!channel) return;

    bindChannel<string>(channel, 'contest-state', () => {
      refetch();
    });

    bindChannel<string>(channel, 'contest-force-refresh', () => {
      refetch();
    });

    const cleanup = () => {
      unbindChannel(channel, 'contest-state');
      unbindChannel(channel, 'contest-force-refresh');
    };

    return cleanup;
  }, [determinedMatchData?.id]);

  useEffect(() => {
    if (!determinedMatchData?.id || !determinedMatchData?.status) {
      messageParent({
        action: 'NAVIGATE',
        source: 'new match page',
        data: {
          url: '/lounge',
        },
      });
      router.push('/lounge');
      return;
    }
    if (!isValidToShowNewMatchPage(determinedMatchData.status)) {
      messageParent({
        action: 'NAVIGATE',
        source: 'new match page',
        data: {
          url: `/matches/${determinedMatchData.id}`,
          targetId: determinedMatchData.id,
        },
      });
      router.push(`/matches/${determinedMatchData.id}`);
    }
  }, [status]);

  const title = !determinedMatchData
    ? `Match ${matchId}`
    : `${determinedMatchData?.creator?.username}'s ${determinedMatchData?.game?.name} Match`;
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property="og:title" content={title} key="title" />
      </Head>
      <PageWrapper topPadding={topPadding}>
        <MatchScreen
          authenticated={authenticated}
          matchData={determinedMatchData}
          matchErrors={matchErrors || error?.graphQLErrors || undefined}
        />
      </PageWrapper>
    </>
  );
};

const getServerMatchData = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  matchId: string,
) => {
  const { data, errors } = await apolloClient.query<
    GetMatchQuery,
    GetMatchQueryVariables
  >({
    query: GetMatchDocument,
    variables: { matchId },
    fetchPolicy: 'network-only',
    errorPolicy: 'all', // This prevents the error being thrown and instead returns the errors array.
  });

  return { matchData: data || null, matchErrors: errors || null };
};

export const getServerSideProps: GetServerSideProps<
  MatchPageProps,
  { matchId: string; topPadding?: string }
> = async (ctx) => {
  const matchId = ctx.params?.matchId || '';

  const apolloClient = initializeApollo(null, ctx);
  const [defaultProps, matchProps] = await Promise.all([
    getDefaultProps(apolloClient, ctx),
    getServerMatchData(apolloClient, matchId),
  ]);

  if (!isValidToShowNewMatchPage(matchProps?.matchData?.contest?.status)) {
    if (!matchProps?.matchData?.contest?.id) {
      return { redirect: { destination: `/lounge`, permanent: false } };
    }
    return {
      redirect: {
        destination: `/matches/${matchProps.matchData.contest.id}`,
        permanent: false,
      },
    };
  }
  const topPadding = parseInt((ctx.query?.topPadding as string) || '0', 10);

  return addApolloState(apolloClient, {
    props: {
      ...defaultProps,
      matchId,
      topPadding,
      ...matchProps,
    },
  });
};

export default LoungeWrapper;
