/* istanbul ignore file */
import { useMemo } from 'react';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
  NormalizedCacheObject,
} from '@apollo/client';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { onError } from '@apollo/client/link/error'; // Import ErrorLink
import { RetryLink } from '@apollo/client/link/retry';
import { concatPagination } from '@apollo/client/utilities';
import fetch from 'isomorphic-unfetch';
import merge from 'deepmerge';
import isEqual from 'lodash.isequal';
import { ON_SERVER } from 'helpers/context';
import getUserToken from 'helpers/get-user-token';
// import { datadogRum } from '@datadog/browser-rum';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { UserFragment } from 'graphpl/core';
import { Themes } from 'components/styles';

const PL_DEVICE = 'web';
const PL_APP_DEVICE = 'app';
const PL_API_VERSION = 5;
const { PL_VERSION } = process.env;

const APOLLO_URI = process.env.APOLLO_SERVER_URL;
const { HYGRAPH_API_URI } = process.env;

// This any type is necessary because the error object is not typed from apollo
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isRetryableError = (error: any): boolean => {
  return (
    error.networkError ||
    (error.response &&
      (error.response.status === 502 || error.response.status === 503))
  );
};

const getLink = (
  ctx?: GetServerSidePropsContext,
  isAppClientSide?: boolean,
) => {
  const rawHeader = ctx?.req?.headers?.['x-pl-device'] || 'web';
  const plDevice = typeof rawHeader === 'string' ? rawHeader : rawHeader[0];
  const isApp = plDevice.toLowerCase() === 'app' || isAppClientSide;

  const httpLink = createHttpLink({
    uri: APOLLO_URI,
    fetch,
    fetchOptions: {
      timeout: 5000,
    },
  });

  const cmsLink = createHttpLink({
    uri: HYGRAPH_API_URI,
    fetch,
  });

  const authLink = setContext(async (_, { headers, ...rest }) => {
    const newHeaders = headers || {};

    if (rest.clientName === 'cms') {
      return { headers: newHeaders };
    }

    const token = await getUserToken(ctx);
    if (token) {
      newHeaders.Authorization = `Bearer ${token}`;
    }
    newHeaders['pl-device'] = isApp ? PL_APP_DEVICE : PL_DEVICE;
    newHeaders['pl-api-version'] = PL_API_VERSION;
    newHeaders['pl-version'] = PL_VERSION;

    return {
      headers: newHeaders,
    };
  });

  const retryLink = new RetryLink({
    attempts: {
      max: 5,
      retryIf: isRetryableError,
    },
    delay: {
      initial: 200,
      max: 2000,
      jitter: true,
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        // eslint-disable-next-line no-console
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }

    // eslint-disable-next-line no-console
    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

  const directionalLink = new RetryLink().split(
    (operation) => operation.getContext().clientName === 'cms',
    authLink.concat(retryLink).concat(cmsLink),
    authLink.concat(retryLink).concat(httpLink),
  );

  return from([errorLink, directionalLink]);
};

const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const createApolloClient = (
  ctx?: GetServerSidePropsContext,
  isApp?: boolean,
): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    ssrMode: ON_SERVER,
    link: getLink(ctx, isApp),
    name: PL_DEVICE,
    version: PL_VERSION,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            allPosts: concatPagination(),
          },
        },
      },
    }),
  });

const initializeApollo = (
  initialState: NormalizedCacheObject | null = null,
  ctx?: GetServerSidePropsContext,
  isApp?: boolean,
) => {
  const tempApolloClient = apolloClient ?? createApolloClient(ctx, isApp);

  if (initialState) {
    const existingCache = tempApolloClient.extract();
    const data = merge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });
    tempApolloClient.cache.restore(data);
  }

  if (process.env.NODE_ENV !== 'production') {
    // Adds messages only in a dev environment
    loadDevMessages();
    loadErrorMessages();
  }

  if (ON_SERVER) return tempApolloClient;

  if (!apolloClient) apolloClient = tempApolloClient;
  return tempApolloClient;
};

const addApolloState = <PageProps>(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: GetServerSidePropsResult<PageProps>,
) => {
  return {
    ...pageProps,
    props: {
      // @ts-ignore
      ...(pageProps.props || {}),
      [APOLLO_STATE_PROP_NAME]: client.cache.extract(),
    },
  };
};

export type ApolloPageProps = {
  host: string;
  isApp: boolean;
  authenticated: boolean;
  theme: Themes;
  user?: UserFragment;
  transparentBackground?: boolean;
  __APOLLO_STATE__?: NormalizedCacheObject | undefined;
};

const useApollo = (
  pageProps: ApolloPageProps,
  ctx?: GetServerSidePropsContext,
) => {
  const { isApp } = pageProps;
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state, ctx, isApp), [
    state,
    ctx,
    isApp,
  ]);
  return store;
};

export { APOLLO_STATE_PROP_NAME, initializeApollo, addApolloState, useApollo };
