import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ThemeProvider } from 'styled-components';
import { AvailableIcons, Iconography, lightTheme } from 'components/styles';
import { useGlobal } from 'components/util/global-context';
import { useModal } from 'components/util/modal-context';
import { useToast } from 'components/util/toast-context';
import SelectInput from 'components/atoms/select-input';
import { ResponsivePill } from 'components/atoms/button/button';
import {
  EntryFeeAmount,
  EntryFeePicker,
} from 'components/atoms/entry-fee-picker';
import { NewRadio } from 'components/atoms/new-radio';
import ServiceFeeComponent from 'components/molecules/service-fee/service-fee';
import { useUser } from 'components/util/user-context';
import { addCommasToNumber } from 'components/util/add-commas-to-number';
import {
  ConsolesFragment,
  UserMatchmakingStatus,
  useSearchInstantMatchMutation,
} from 'graphpl/core';
import MlbPlatformModal, {
  isMLBPlatformError,
} from 'components/organisms/mlb-platform-modal';
import depositNowToast, {
  isLackOfFundsError,
} from 'components/molecules/deposit-now-toast';
import { NewCheckbox } from 'components/atoms/new-checkbox';
import AddGamertag, {
  AddGamertagHeader,
  gamertagIsMissing,
  messageMap,
} from 'components/organisms/add-gamertag-modal';
import { CenterSpinner } from 'components/atoms/loading-spinner';
import { messageParent } from 'helpers/web-view';
import { Divider } from 'components/atoms';
import {
  getDefaultGame,
  getGame,
  getMMEnabledGames,
} from '../utilities/create-match-game-helpers';
import {
  BottomWrapper,
  CheckboxDescription,
  CheckboxName,
  CheckboxRow,
  CheckboxTextWrapper,
  CheckboxToggleWrapper,
  CheckboxWrapper,
  HeaderIconWrapper,
  HeaderText,
  HeaderWrapper,
  InputWrapper,
  ModalWrapper,
  ServiceFeeText,
  SubHeader,
  SubHeaderText,
  TitleWrapper,
  FreePlayPill,
  LCButtonWrapper,
  RightButtonAdditionalPadding,
  DisabledWrapper,
  TopContents,
  BottomPadding,
} from './solo-match-modal.styles';
import {
  getConsole,
  getConsoles,
  getConsolesForSelectInput,
} from '../utilities/create-match-console-helpers';

const LC_MULTIPLIER = 100;
const TWITCH_NOT_LINKED =
  'A twitch account must be linked to play with this game format';

const defaultAmounts: EntryFeeAmount[] = [
  { value: 5, label: '$5' },
  { value: 10, label: '$10' },
  { value: 25, label: '$25' },
  { value: 50, label: '$50' },
];

export const SoloMatchModal = ({
  storybookDisabled = false,
  topPadding = 0,
  bottomPadding = 0,
}: {
  storybookDisabled?: boolean;
  topPadding?: number;
  bottomPadding?: number;
}) => {
  const router = useRouter();
  const theme = lightTheme;
  const { games, consoles } = useGlobal();
  const { user, setUserRaw } = useUser();
  const { displayModal, dismissModal } = useModal();
  const { displayToast, dismissToast } = useToast();
  const defaultGame = getDefaultGame({
    games,
    user,
  });

  const amounts = [
    { value: 0, label: 'Free', disabled: !user?.hasPlPlus },
    ...defaultAmounts,
  ];

  const allGamesEnabled = games.filter(
    ({ enabled, matchmakingEnabled }) => matchmakingEnabled && enabled,
  );
  const gameOptions = getMMEnabledGames({ games });
  const [selectedGame, setSelectedGame] = useState(defaultGame);

  const availableConsoles = getConsoles({
    selectedGame,
    consoles,
    user,
  });
  const [selectedConsole, setSelectedConsole] = useState<ConsolesFragment>(
    availableConsoles[0],
  );
  const consoleOptions = getConsolesForSelectInput({
    consoles: availableConsoles,
  });

  const gameFormats = (selectedGame?.gameFormats || []).filter(
    (gameFormat) =>
      Boolean(gameFormat) &&
      gameFormat?.enabled &&
      gameFormat?.key?.includes('plc'),
  );
  const [selectedGameFormat, setSelectedGameFormat] = useState<
    string | undefined
  >();
  const [selectedRules, setSelectedRules] = useState<string[]>([]);
  const [selectedAmount, setSelectedAmount] = useState<
    EntryFeeAmount | undefined
  >();

  const setAmounts = (amount: EntryFeeAmount) => {
    setSelectedAmount((prev) => {
      if (!prev) {
        return amount;
      }
      if (amount.value === prev.value) {
        return undefined;
      }
      return amount;
    });
  };

  const changeSelectedGameFormat = (gameFormatKey: string) => {
    if (selectedGameFormat === gameFormatKey) {
      setSelectedGameFormat(undefined);
      return;
    }
    setSelectedGameFormat(gameFormatKey);
  };

  const changeSelectedRules = (ruleKey: string) => {
    if (selectedRules.includes(ruleKey)) {
      setSelectedRules((prev) => prev.filter((key) => key !== ruleKey));
      return;
    }
    setSelectedRules((prev) => [...prev, ruleKey]);
  };

  useEffect(() => {
    setSelectedGameFormat(undefined);
    setSelectedRules([]);

    const newGameConsoles = getConsoles({
      selectedGame,
      consoles,
      user,
    });
    setSelectedConsole(newGameConsoles[0]);
  }, [selectedGame]);

  const [
    startSearch,
    { loading, error, called },
  ] = useSearchInstantMatchMutation({
    onCompleted: () => {
      messageParent({
        action: 'REFETCH_USER',
        source: 'SEARCHING_MODAL',
      });
      dismissModal();
      setUserRaw((prev) => {
        if (!prev) return prev;
        if (prev?.status?.matchmakingStatus === UserMatchmakingStatus.SEARCHING)
          return prev;
        return {
          ...prev,
          status: {
            ...prev.status,
            matchmakingStatus: UserMatchmakingStatus.SEARCHING,
          },
        };
      });

      displayToast({
        subtext: 'Succefully setup solo match',
        type: 'success',
        toastDisplayed: true,
      });
    },
    onError: (err) => {
      if (isMLBPlatformError(err.message)) {
        displayToast({
          toastDisplayed: true,
          type: 'error',
          disableTimeout: true,
          subtext: <MlbPlatformModal onClick={dismissToast} />,
        });
        return;
      }

      if (isLackOfFundsError(err.message)) {
        depositNowToast(displayToast, dismissToast, dismissModal);
        return;
      }

      if (err.message === TWITCH_NOT_LINKED) {
        dismissModal();
        router.push('/user/linked-accounts');
        return;
      }

      if (gamertagIsMissing(err.message)) {
        messageParent({
          action: 'NAVIGATE',
          source: 'match-creation-modal',
          data: {
            url: '/user/gamertags',
            targetId: messageMap[err.message],
          },
        });
        displayModal({
          type: 'default',
          header: <AddGamertagHeader message={err.message} />,
          body: <AddGamertag message={err.message} />,
        });
        return;
      }

      displayToast({
        heading: 'An error occured',
        subtext: err.message,
        type: 'error',
        toastDisplayed: true,
      });
    },
  });

  const handleSubmit = (currency: 'PL_BALANCE' | 'LOUNGE_CREDITS') => () => {
    if (!selectedGame || !selectedGame.id || !selectedGame.gameSeriesId) return;
    if (!selectedConsole) return;
    if (!selectedAmount) return;
    if (!selectedGameFormat) return;

    const userHasEnoughLoungeCredits =
      selectedAmount.value * LC_MULTIPLIER <=
      (user?.wallet?.loungeCredits || 0);

    if (!userHasEnoughLoungeCredits && currency === 'LOUNGE_CREDITS') {
      if (user?.hasPlPlus) {
        displayToast({
          toastDisplayed: true,
          type: 'info',
          subtext:
            'Try earning some more lounge credits in tournaments or play PL Connect for free!',
          heading: 'Not enough Lounge Credits',
        });
        return;
      }

      displayToast({
        toastDisplayed: true,
        type: 'info',
        subtext:
          'Subscribe to PL+ so you can earn lounge credits by playing PL Connect for free!',
        heading: 'Not enough Lounge Credits',
        firstBtnText: 'Subscribe to PL+',
        firstBtnHref: '/subscribe?source=attempted_free_matchmaking',
        displayOptions: true,
      });
      return;
    }
    startSearch({
      variables: {
        search: {
          amountMin: selectedAmount.value,
          currency,
          amountMax: selectedAmount.value,
          gameId: selectedGame.id,
          consoleSettingId: selectedConsole?.id || 'ps5',
          gameSeriesId: selectedGame.gameSeriesId,
          gameFormatIds: [selectedGameFormat],
          rules: selectedRules || undefined,
        },
      },
    });
  };

  const disabledInputs = storybookDisabled || loading || (called && !error);

  const disabledButton =
    !selectedGame ||
    !selectedGame.name ||
    !selectedConsole?.id ||
    !selectedGameFormat ||
    !selectedAmount ||
    disabledInputs;

  const shouldShowLCPlacement = Boolean(
    selectedAmount &&
      selectedAmount.value &&
      selectedAmount.value !== 0 &&
      selectedAmount.value <= 25,
  );
  const costInLc = (selectedAmount?.value || 10) * LC_MULTIPLIER;
  return (
    <ThemeProvider theme={lightTheme}>
      <ModalWrapper topPadding={topPadding} bottomPadding={bottomPadding}>
        <TopContents>
          <HeaderWrapper>
            <TitleWrapper>
              <HeaderText>Solo match</HeaderText>
              <SubHeaderText>
                Beat a random online opponent; Twitch required.
              </SubHeaderText>
            </TitleWrapper>
            <HeaderIconWrapper onClick={dismissModal}>
              <Iconography
                name={AvailableIcons.CROSS}
                color={lightTheme.new.content}
              />
            </HeaderIconWrapper>
          </HeaderWrapper>
          <SubHeader>Game</SubHeader>
          <InputWrapper>
            <SelectInput
              id="solo-match-game-selector"
              name="solo-match-game-selector"
              defaultValue="chooseGame"
              label="Game"
              isTransparent
              options={gameOptions}
              onChange={(event) => {
                setSelectedGame(getGame(event?.target?.value, allGamesEnabled));
              }}
              value={selectedGame.name as string}
              disabled={disabledInputs}
            />
          </InputWrapper>
          {consoleOptions.length > 1 && (
            <>
              <SubHeader>Platform</SubHeader>
              <InputWrapper>
                <SelectInput
                  id="solo-match-console-selector"
                  name="solo-match-console-selector"
                  defaultValue="choosePlatform"
                  label="Your Platform"
                  isTransparent
                  options={consoleOptions}
                  onChange={(event) => {
                    setSelectedConsole(
                      getConsole(event?.target?.value, consoles),
                    );
                  }}
                  value={selectedConsole?.displayName || ''}
                  disabled={disabledInputs}
                />
              </InputWrapper>
            </>
          )}
          <SubHeader>Game mode</SubHeader>
          <CheckboxWrapper>
            {gameFormats.map((gameFormat) => {
              if (!gameFormat || !gameFormat.name || !gameFormat.key)
                return null;

              return (
                <CheckboxRow
                  key={gameFormat.name}
                  onClick={() => {
                    if (disabledInputs) return;
                    changeSelectedGameFormat(gameFormat.key as string);
                  }}
                  disabled={disabledInputs}
                >
                  <CheckboxToggleWrapper>
                    <NewRadio checked={selectedGameFormat === gameFormat.key} />
                  </CheckboxToggleWrapper>
                  <CheckboxTextWrapper>
                    <CheckboxName>{gameFormat.name}</CheckboxName>
                    <CheckboxDescription>
                      {gameFormat.description}
                    </CheckboxDescription>
                  </CheckboxTextWrapper>
                </CheckboxRow>
              );
            })}
          </CheckboxWrapper>
          {selectedGame.rules && selectedGame.rules.length >= 1 && (
            <>
              <SubHeader>Additional rules</SubHeader>
              <CheckboxWrapper>
                {selectedGame.rules.map((rule) => {
                  if (!rule || !rule.name || !rule.key) return null;

                  return (
                    <CheckboxRow
                      key={rule.name}
                      onClick={() => {
                        if (disabledInputs) return;
                        changeSelectedRules(rule.key as string);
                      }}
                      disabled={disabledInputs}
                    >
                      <CheckboxToggleWrapper>
                        <NewCheckbox
                          checked={selectedRules.includes(rule.key)}
                          isTransparent
                          highContrast
                          inverse
                        />
                      </CheckboxToggleWrapper>
                      <CheckboxTextWrapper>
                        <CheckboxName>{rule.name}</CheckboxName>
                        <CheckboxDescription>
                          {rule.description}
                        </CheckboxDescription>
                      </CheckboxTextWrapper>
                    </CheckboxRow>
                  );
                })}
              </CheckboxWrapper>
            </>
          )}
          <SubHeader>Entry amount</SubHeader>
          <EntryFeePicker
            type="default"
            amounts={amounts}
            minAmount={selectedAmount}
            setAmounts={setAmounts}
            disabled={disabledInputs}
            disabledOnClick={() => {
              displayToast({
                toastDisplayed: true,
                type: 'info',
                heading: 'PL+ Required',
                subtext:
                  'In order to play for free, you need to be a PL+ member',
                displayOptions: true,
                firstBtnHref: '/subscribe?source=attempted_free_matchmaking',
                firstBtnOnClick: () => {
                  dismissModal();
                  dismissToast();
                },
                firstBtnText: 'Subscribe',
                secondBtnOnClick: () => {
                  dismissToast();
                },
                secondBtnText: 'Not right now',
              });
            }}
          />
        </TopContents>
        <BottomWrapper>
          <Divider />
          <BottomPadding>
            <ServiceFeeText>
              {selectedAmount && selectedAmount.value !== 0 && (
                <ServiceFeeComponent
                  selectedAmounts={
                    selectedAmount ? [{ value: selectedAmount.value }] : []
                  }
                  userHasPlPlus={user?.hasPlPlus || false}
                />
              )}
            </ServiceFeeText>
            {shouldShowLCPlacement &&
              (user?.hasPlPlus ? (
                <FreePlayPill
                  size="large"
                  purpose="success"
                  disabled={disabledButton}
                  onClick={handleSubmit('LOUNGE_CREDITS')}
                >
                  {loading ? (
                    <CenterSpinner size={24} />
                  ) : (
                    <LCButtonWrapper>
                      <Iconography
                        name={AvailableIcons.LOUNGE_CREDITS}
                        color={theme.new.surface}
                      />
                      <p>Use {addCommasToNumber(costInLc)} Credits</p>
                      <RightButtonAdditionalPadding />
                    </LCButtonWrapper>
                  )}
                </FreePlayPill>
              ) : (
                <DisabledWrapper>
                  <FreePlayPill
                    size="large"
                    purpose="success"
                    disabled={disabledButton}
                    onClick={() => {
                      displayToast({
                        toastDisplayed: true,
                        type: 'info',
                        heading: 'PL+ Required',
                        subtext:
                          'PL+ membership is required to use Lounge Credits',
                        firstBtnText: 'Click here to subscribe',
                        firstBtnHref:
                          '/subscribe?source=attempted_free_matchmaking',
                        firstBtnOnClick: () => {
                          dismissModal();
                          dismissToast();
                        },
                        displayOptions: true,
                      });
                    }}
                  >
                    {loading ? (
                      <CenterSpinner size={24} />
                    ) : (
                      <LCButtonWrapper>
                        <Iconography
                          name={AvailableIcons.LOUNGE_CREDITS}
                          color={theme.new.surface}
                        />
                        <p>Use {addCommasToNumber(costInLc)} Credits</p>
                        <RightButtonAdditionalPadding />
                      </LCButtonWrapper>
                    )}
                  </FreePlayPill>
                </DisabledWrapper>
              ))}
            <ResponsivePill
              size="large"
              purpose="success"
              disabled={disabledButton}
              onClick={handleSubmit('PL_BALANCE')}
            >
              {loading ? <CenterSpinner size={24} /> : 'Confirm match'}
            </ResponsivePill>
          </BottomPadding>
        </BottomWrapper>
      </ModalWrapper>
    </ThemeProvider>
  );
};
