import React from 'react';
import { MatchContestFragment } from 'graphpl/core';
import { useUser } from 'components/util/user-context';
import { GraphQLError } from 'graphql';
import { CenterSpinner } from 'components/atoms/loading-spinner';
import { useTheme } from 'styled-components';
import {
  BottomSectionWrapper,
  Logo,
  MatchDetailsColumn,
  MatchScreenWrapper,
} from './match-screen.styles';
import { MatchDetailsSection } from './components/match-details-section';
import {
  MatchRulesSection,
  PLRulesSection,
} from './components/match-rules-section';
import { MatchHeaderSection } from './components/match-header-section';
import DarkLogo from './assets/logo-dark.svg';
import LightLogo from './assets/logo-light.svg';
import { PlayersJoinedPreview } from './components/players-joined-preview';
import { MatchChat } from './components/match-chat';
import {
  getActiveInvites,
  getActiveQueue,
  getInvitedUsers,
} from './components/players-joined-preview/players-joined-preview';

type MatchScreenProps = {
  authenticated: boolean;
  matchData: MatchContestFragment | null | undefined;
  matchErrors?: readonly GraphQLError[];
};

export const MatchScreen = ({ authenticated, matchData }: MatchScreenProps) => {
  const theme = useTheme();
  const { user } = useUser();

  if (!matchData) return <CenterSpinner size={48} />;

  const userIsLoggedIn = !(!authenticated && !user?.id);
  const isOwner = user?.id === matchData?.creator?.id;

  const activeQueue = getActiveQueue(matchData);
  const activeInvites = getActiveInvites(matchData);
  const users = getInvitedUsers({
    invites: activeInvites,
    playerQueue: activeQueue,
  });

  const totalNumberOfPlayers = users.length;
  const playerInvitesPresent = totalNumberOfPlayers >= 1;

  return (
    <>
      <MatchScreenWrapper userIsLoggedIn={userIsLoggedIn}>
        <MatchDetailsColumn>
          {!userIsLoggedIn && (
            <Logo>
              {theme.new.theme === 'dark' ? <LightLogo /> : <DarkLogo />}
            </Logo>
          )}
          <MatchHeaderSection
            matchData={matchData}
            authenticated={authenticated}
          />
          {userIsLoggedIn && <MatchDetailsSection matchData={matchData} />}
        </MatchDetailsColumn>
        {userIsLoggedIn && (
          <MatchDetailsColumn>
            {isOwner && <PlayersJoinedPreview matchData={matchData} />}
            <MatchChat
              matchData={matchData}
              playerInvitesPresent={isOwner && playerInvitesPresent}
            />
          </MatchDetailsColumn>
        )}
      </MatchScreenWrapper>
      {userIsLoggedIn && (
        <BottomSectionWrapper>
          {matchData?.fullRules && (
            <MatchRulesSection fullRules={matchData.fullRules} />
          )}
          {matchData?.plRules && <PLRulesSection plRules={matchData.plRules} />}
        </BottomSectionWrapper>
      )}
    </>
  );
};
