import React, { PropsWithChildren, createContext, useContext } from 'react';
import {
  ConsolesFragment,
  GameSeriesFragment,
  GamesFragment,
  LobbiesFragment,
} from 'graphpl/core';
import {
  AdLocations,
  AdvertFragment,
  CmsTournamentFragment,
  FeaturedStreamFragment,
  FeatureFlagFragment,
  PromoBannerFragment,
  TournamentFilterFragment,
} from 'graphpl/cms';
import cmsCacheConfig from './cache/cms-config.json';
import coreCacheConfig from './cache/core-config.json';
import { mapFeatureFlags } from './utilities/map-feature-flags';
import { featureFlagFunc } from './utilities/feature-flag-func';
import { featuredStreamFunc } from './utilities/featured-stream';
import { advertToShowFunc } from './utilities/advert-func';
import { tournamentInfoFunc } from './utilities/tournament-info-func';

type GlobalContextType = {
  lobbies: LobbiesFragment[];
  games: GamesFragment[];
  consoles: ConsolesFragment[];
  gameSeriess: GameSeriesFragment[];
  featureFlags: Record<string, FeatureFlagFragment>;
  featureFlag: (flag: string) => FeatureFlagFragment;
  featuredStreams: FeaturedStreamFragment[];
  featuredStream: (gameSeries: string) => FeaturedStreamFragment | undefined;
  adverts: AdvertFragment[];
  advertToShow: (props: {
    context: AdLocations;
    location?: string;
  }) => AdvertFragment | undefined;
  promoBanners: PromoBannerFragment[];
  quickFilters: TournamentFilterFragment[];
  cmsTournaments: CmsTournamentFragment[];
  tournamentInfo(
    internalId?: string | number | null,
  ): CmsTournamentFragment | undefined;
};

const initialAllFeatureFlags = mapFeatureFlags(
  cmsCacheConfig.featureFlags as FeatureFlagFragment[],
);

const initialFeatureFlag = featureFlagFunc(initialAllFeatureFlags);

const initialFeaturedStream = featuredStreamFunc(
  cmsCacheConfig.featuredStreams as FeaturedStreamFragment[],
);

const initialAdvertToShow = advertToShowFunc(
  cmsCacheConfig.adverts as AdvertFragment[],
);

export const GlobalContext = createContext<GlobalContextType>({
  lobbies: coreCacheConfig.lobbies as LobbiesFragment[],
  games: coreCacheConfig.games as GamesFragment[],
  consoles: coreCacheConfig.consoles as ConsolesFragment[],
  featureFlags: initialAllFeatureFlags,
  featureFlag: initialFeatureFlag,
  featuredStreams: cmsCacheConfig.featuredStreams as FeaturedStreamFragment[],
  featuredStream: initialFeaturedStream,
  adverts: cmsCacheConfig.adverts as AdvertFragment[],
  advertToShow: initialAdvertToShow,
  promoBanners: cmsCacheConfig.promoBanners as PromoBannerFragment[],
  gameSeriess: coreCacheConfig.gameSeriess as GameSeriesFragment[],
  quickFilters: cmsCacheConfig.quickFilters as TournamentFilterFragment[],
  cmsTournaments: cmsCacheConfig.tournaments as CmsTournamentFragment[],
  tournamentInfo: tournamentInfoFunc(
    cmsCacheConfig.tournaments as CmsTournamentFragment[],
  ),
});

export const GlobalProvider = ({
  mockLobbies,
  mockGames,
  mockConsoles,
  mockGameSeries,
  mockFeatureFlags,
  mockAdverts,
  children,
}: PropsWithChildren<{
  mockLobbies?: LobbiesFragment[];
  mockGames?: GamesFragment[];
  mockGameSeries?: GameSeriesFragment[];
  mockConsoles?: ConsolesFragment[];
  mockFeatureFlags?: FeatureFlagFragment[];
  mockAdverts?: AdvertFragment[];
}>) => {
  const allFeatureFlags = mapFeatureFlags(
    mockFeatureFlags || (cmsCacheConfig.featureFlags as FeatureFlagFragment[]),
  );

  const featureFlag = featureFlagFunc(allFeatureFlags);

  const featuredStream = featuredStreamFunc(
    cmsCacheConfig.featuredStreams as FeaturedStreamFragment[],
  );

  const advertToShow = advertToShowFunc(
    mockAdverts || (cmsCacheConfig.adverts as AdvertFragment[]),
  );

  return (
    <GlobalContext.Provider
      value={{
        lobbies: mockLobbies || (coreCacheConfig.lobbies as LobbiesFragment[]),
        games: mockGames || (coreCacheConfig.games as GamesFragment[]),
        consoles:
          mockConsoles || (coreCacheConfig.consoles as ConsolesFragment[]),
        featureFlags: allFeatureFlags,
        featureFlag,
        featuredStreams: cmsCacheConfig.featuredStreams as FeaturedStreamFragment[],
        featuredStream,
        adverts: mockAdverts || (cmsCacheConfig.adverts as AdvertFragment[]),
        advertToShow,
        promoBanners: cmsCacheConfig.promoBanners as PromoBannerFragment[],
        gameSeriess:
          mockGameSeries ||
          (coreCacheConfig.gameSeriess as GameSeriesFragment[]),
        quickFilters: cmsCacheConfig.quickFilters as TournamentFilterFragment[],
        cmsTournaments: cmsCacheConfig.tournaments as CmsTournamentFragment[],
        tournamentInfo: tournamentInfoFunc(
          cmsCacheConfig.tournaments as CmsTournamentFragment[],
        ),
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => useContext(GlobalContext);
