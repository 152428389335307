import React from 'react';
import truncateStringInMiddle from 'components/util/format';
import UsernameSpan, { UsernameFontSize } from './username.styles';

export type UsernameProps = {
  username: string;
  fontSize?: UsernameFontSize;
  className?: string;
  offline?: boolean;
  animated?: boolean;
  maxDisplayChars?: number;
  underlined?: boolean;
};

const Username = ({
  username,
  fontSize = 'small',
  className,
  offline,
  animated,
  maxDisplayChars,
  underlined,
}: UsernameProps) => {
  const displayedUsername = maxDisplayChars
    ? truncateStringInMiddle(username, maxDisplayChars)
    : username;

  return (
    <UsernameSpan
      className={className}
      title={username}
      fontSize={fontSize}
      offline={Boolean(offline)}
      animated={Boolean(animated)}
      underlined={Boolean(underlined)}
    >
      {displayedUsername}
    </UsernameSpan>
  );
};

export default Username;
