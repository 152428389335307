import { FC, SVGProps } from 'react';
import Discord from './assets/socials/discord.svg';
import Facebook from './assets/socials/facebook.svg';
import Instagram from './assets/socials/instagram.svg';
import LoungeCredits from './assets/socials/lounge-credits.svg';
import PLPlus from './assets/socials/pl-plus.svg';
import Tiktok from './assets/socials/tiktok.svg';
import Twitch from './assets/socials/twitch.svg';
import Twitter from './assets/socials/twitter.svg';
import Whatsapp from './assets/socials/whatsapp.svg';

// eslint-disable-next-line no-shadow
export enum SocialIcons {
  DISCORD = 'Discord',
  FACEBOOK = 'Facebook',
  INSTAGRAM = 'Instagram',
  LOUNGE_CREDITS = 'LoungeCredits',
  PL_PLUS = 'PLPlus',
  TIKTOK = 'Tiktok',
  TWITCH = 'Twitch',
  TWITTER = 'Twitter',
  WHATSAPP = 'Whatsapp',
}

export const socialIconsComponentMap: Record<
  SocialIcons,
  FC<SVGProps<SVGElement>>
> = {
  Discord,
  Facebook,
  Instagram,
  LoungeCredits,
  PLPlus,
  Tiktok,
  Twitch,
  Twitter,
  Whatsapp,
};
