export const crossplayConsoles = ['ps5', 'xboxX'];
export const crossplayGames = ['madden', 'nba', 'fifa', 'eafc', 'cfb'];

export const isCrossplayGame = (game: string): boolean => {
  return crossplayGames.includes(game);
};

export const isCrossplayConsole = (console: string): boolean => {
  return crossplayConsoles.includes(console);
};

export const isCrossplay = (console: string, game: string): boolean => {
  return isCrossplayGame(game) && isCrossplayConsole(console);
};

export const convertConsoleToCorrectUpper = (console: string): string => {
  switch (console) {
    case 'XBOXX':
      return 'XboxX';
    case 'XBOXS':
      return 'XboxS';
    case 'XBOXONE':
      return 'XboxOne';
    default:
      return console;
  }
};

export const delay = async (timeout?: number) =>
  new Promise((resolve) => setTimeout(resolve, timeout || 1000));

export const jitterCalculation = ({
  breadth,
  minimum,
}: {
  breadth: number;
  minimum: number;
}) => Math.floor(Math.random() * breadth) + minimum;

/**
 * Jitter delay takes a d bredth and minimum and returns a promise that resolves after a random amount of time between the minimum and the minimum + breadth. All in milliseconds.
 */
export const jitterDelay = async ({
  breadth = 5000,
  minimum = 1000,
}: {
  breadth?: number;
  minimum?: number;
}) => {
  const timeout = jitterCalculation({ breadth, minimum });

  return delay(timeout);
};
