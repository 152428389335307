import React from 'react';
import { useTheme } from 'styled-components';
import { Iconography, AvailableIcons } from 'components/styles';
import { ValidColor } from 'components/styles/colors';
import {
  LabelText,
  Container,
  iconSizeMap,
  colors,
  IconWrapper,
  LabelWrapper,
} from './label.styles';

export type LabelPalette = {
  border: ValidColor;
  background: ValidColor;
  text: ValidColor;
  icon: ValidColor;
};

export type LabelSizing = 'large' | 'medium' | 'small';

export type LabelVariant = 'outline' | 'subtle' | 'contrast';

// eslint-disable-next-line no-shadow
export enum LabelType {
  'default' = 'default',
  'new' = 'new',
  'information' = 'information',
  'success' = 'success',
  'error' = 'error',
  'warning' = 'warning',
  'unrated' = 'unrated',
  'muchEasier' = 'muchEasier',
  'easier' = 'easier',
  'balanced' = 'balanced',
  'challenging' = 'challenging',
  'veryChallenging' = 'veryChallenging',
}

export const Label = ({
  type = 'default',
  size = 'medium',
  variant = 'subtle',
  content,
  startIcon,
  endIcon,
  disabled = false,
}: {
  type?: keyof typeof LabelType;
  size?: LabelSizing;
  variant?: LabelVariant;
  content?: string;
  startIcon?: AvailableIcons;
  endIcon?: AvailableIcons;
  disabled?: boolean;
}) => {
  const theme = useTheme();
  const colorMap: LabelPalette = colors(theme)[variant][type];
  return (
    <LabelWrapper>
      <Container
        variant={variant}
        size={size}
        disabled={disabled}
        styling={colorMap}
        startIcon={Boolean(startIcon)}
        endIcon={Boolean(endIcon)}
        hasContent={Boolean(content)}
      >
        {startIcon && (
          <IconWrapper size={size} startIcon hasContent={Boolean(content)}>
            <Iconography
              size={iconSizeMap[size]}
              color={colorMap.icon}
              name={startIcon}
            />
          </IconWrapper>
        )}
        {content && (
          <LabelText
            size={size}
            styling={colorMap}
            startIcon={Boolean(startIcon)}
            endIcon={Boolean(endIcon)}
          >
            {content}
          </LabelText>
        )}
        {endIcon && (
          <IconWrapper size={size} endIcon hasContent={Boolean(content)}>
            <Iconography
              size={iconSizeMap[size]}
              color={colorMap.icon}
              name={endIcon}
            />
          </IconWrapper>
        )}
      </Container>
    </LabelWrapper>
  );
};
