import { AdLocations, AdvertFragment } from 'graphpl/cms';
import { isWithinInterval } from 'date-fns';

const isValidAdvert = (
  advert: AdvertFragment,
  context: AdLocations,
  location?: string,
): AdvertFragment | undefined => {
  if (!advert.enabled) return;

  const active = isWithinInterval(Date.now(), {
    start: new Date(advert.startTime),
    end: new Date(advert.endTime),
  });

  if (!active) return;

  const validContextLocation = advert.locationsShown.includes(context);

  if (!validContextLocation) return;
  if (!location) return advert;

  const invalidLocationPath = advert.disabledLocations.includes(location);

  if (invalidLocationPath) return;

  return advert;
};

export const advertToShowFunc = (allAdverts: AdvertFragment[]) => ({
  context,
  location,
}: {
  context: AdLocations;
  location?: string;
}): AdvertFragment | undefined => {
  if (!context) return;

  const validAdverts = allAdverts.filter((advert) =>
    isValidAdvert(advert, context, location),
  );

  if (!validAdverts.length) return;
  if (validAdverts.length === 0) return;
  if (validAdverts.length === 1) return validAdverts[0];
  const totalWeight = validAdverts.reduce(
    (acc, advert) => acc + advert.weighting,
    0,
  );

  const randomWeight = Math.floor(Math.random() * totalWeight);

  const advertToShow = validAdverts.reduce<{
    count: number;
    advert: AdvertFragment | undefined;
  }>(
    (acc, advert) => {
      if (acc.advert) return acc;
      if (advert.weighting > acc.count) return { count: 0, advert };
      return { count: acc.count - advert.weighting, advert: undefined };
    },
    { count: randomWeight, advert: undefined },
  );

  return advertToShow.advert;
};
