import React, { useState, useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import { T } from 'react-polyglot-hooks';
import SVG from 'svgs';
import { useToast } from 'components/util/toast-context';
import { Pill } from 'components/atoms/button';
import { Banner } from 'components/atoms/banner';
import { UserBalance } from 'components/molecules/user-balance';
import GlovalNavElement from 'components/atoms/global-nav-element';
import { closeOnClickOutside } from 'helpers';
import {
  fireButtonAnalytics,
  CLICK_BUTTON,
  GLOBAL_HEADER_VERIFICATION_RESEND,
  DIRECT_MESSAGES_GLOBAL_NAV_ELEMENT,
} from 'analytics';
import { useUser } from 'components/util/user-context';
import { useChat } from 'components/util/chat-context';
import Link from 'components/atoms/link';
import { useResendEmailVerificationMutation } from 'graphpl/core';
import {
  LoggedOutWrap,
  LoggedInWrap,
  HeaderWrap,
  LeftSignedInWrap,
  LogoWithoutLink,
  Logo,
  StyledLink,
  MobileDropdownMenu,
  SytledLinkWithPadding,
  MobileDirectMessages,
  SignedInHeaderWrap,
  BannerText,
  UserEmail,
  TwitchLandscape,
  PaddingBlock,
  DepositButton,
  DepositButtonText,
} from './header.styles';
import UserHeaderDropdownMenu from './user-header-dropdown-menu';
import Notifications from './notification-dropdown';

let buttonTimeoutId: NodeJS.Timeout | null = null;

type SignedInLinksProps = {
  isOpen?: boolean;
  storybookDisabled?: boolean;
  delayOverwrite?: number;
  disableMenu?: boolean;
  displayDepositButton?: boolean;
};

const SignedInLinks = ({
  isOpen,
  storybookDisabled = false,
  delayOverwrite,
  disableMenu,
  displayDepositButton = false,
}: SignedInLinksProps) => {
  const TIMEOUT = delayOverwrite || 5000;

  const { user } = useUser();
  const { route } = useRouter();
  const { displayToast } = useToast();
  // @ts-ignore
  const { unreadMessages } = useChat();

  const dmEnabled = process.env.DIRECT_MESSAGES === 'true';

  const [buttonDisabled, setButtonDisabled] = useState(storybookDisabled);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(
    isOpen || false,
  );

  const listRef = useRef(null);
  const pickerRef = useRef(null);

  useEffect(
    () =>
      closeOnClickOutside({
        setState: setIsNotificationsOpen,
        buttonRef: pickerRef,
        contentRef: listRef,
      }),
    [listRef, pickerRef, isNotificationsOpen],
  );

  const [
    sendVerificationEmail,
    { data: mutationData, error: mutationError, loading: mutationLoading },
  ] = useResendEmailVerificationMutation({
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (!mutationLoading && mutationError) {
      displayToast({
        toastDisplayed: true,
        type: 'error',
        subtext: mutationError.message,
      });
    }
    if (!mutationLoading && mutationData?.resendEmailVerification) {
      displayToast({
        toastDisplayed: true,
        type: 'success',
        subtext: <T phrase="emailVerification.verificationSent" />,
      });
    }
  }, [mutationError, mutationLoading, mutationData]); // eslint-disable-line react-hooks/exhaustive-deps

  const disableInput = () => {
    if (buttonTimeoutId) {
      clearTimeout(buttonTimeoutId);
      buttonTimeoutId = null;
    }

    setButtonDisabled(true);

    buttonTimeoutId = setTimeout(() => {
      setButtonDisabled(false);
    }, TIMEOUT);
  };

  const resendEmailVerification = () => {
    fireButtonAnalytics({
      event: CLICK_BUTTON,
      source: GLOBAL_HEADER_VERIFICATION_RESEND,
    });
    disableInput();
    sendVerificationEmail();
  };

  return (
    <>
      <SignedInHeaderWrap>
        <LeftSignedInWrap>
          {!disableMenu ? (
            <MobileDropdownMenu hamburgerMenu />
          ) : (
            <PaddingBlock />
          )}
          {disableMenu ? (
            <LogoWithoutLink>
              <SVG icon="playersLoungeSportsFull" data-testid="logo-light" />
            </LogoWithoutLink>
          ) : (
            <Logo href="/lounge">
              <SVG icon="playersLoungeSportsFull" data-testid="logo-light" />
            </Logo>
          )}
        </LeftSignedInWrap>
        <div>
          {!disableMenu && !displayDepositButton && (
            <LoggedInWrap>
              <UserHeaderDropdownMenu />
              <Notifications notificationCount={user?.notificationCount || 0} />
              {dmEnabled && (
                <GlovalNavElement
                  href={`/messages/?source=${DIRECT_MESSAGES_GLOBAL_NAV_ELEMENT}`}
                  displayRedDot={unreadMessages > 0}
                  selected={route === '/messages'}
                  text="Messages"
                  icon="messages"
                />
              )}
              <UserBalance
                currency="$"
                value={`${user?.balance || '0.00'}`}
                loungeCredits={user?.wallet?.loungeCredits}
                themeOverwrite="dark"
              />
            </LoggedInWrap>
          )}
          {!disableMenu && displayDepositButton && (
            <div>
              <Link href="/deposit?ds=onboarding">
                <DepositButton>
                  <DepositButtonText>
                    <T phrase="depositInstead" />
                  </DepositButtonText>
                </DepositButton>
              </Link>
            </div>
          )}
          {!disableMenu && !displayDepositButton && dmEnabled && (
            <MobileDirectMessages>
              <GlovalNavElement
                href={`/messages/?source=${DIRECT_MESSAGES_GLOBAL_NAV_ELEMENT}`}
                displayRedDot={unreadMessages > 0}
                selected={route === '/messages'}
                text="Messages"
                icon="messages"
              />
            </MobileDirectMessages>
          )}
        </div>
      </SignedInHeaderWrap>
      {!user?.isVerified && (
        <Banner type="info" header={<T phrase="accountNotVerrified" />}>
          <BannerText>
            <T phrase="emailVerification.weSentVerification" />
            <UserEmail>{user?.email}</UserEmail>
            <T phrase="emailVerification.checkInbox" />
          </BannerText>
          <Pill
            purpose="infoInverted"
            disabled={buttonDisabled}
            onClick={resendEmailVerification}
            size="small"
          >
            <T phrase="emailVerification.sendAgain" />
          </Pill>
        </Banner>
      )}
    </>
  );
};

const SignedOutLinks = () => (
  <HeaderWrap>
    <Logo href="/lounge">
      <SVG icon="playersLoungeSportsFull" data-testid="logo-light" />
    </Logo>

    <LoggedOutWrap>
      <SytledLinkWithPadding href="/login" data-testid="login-link">
        <T phrase="login" />
      </SytledLinkWithPadding>
      <StyledLink href="/signup" data-testid="signup-link">
        <Pill size="small" purpose="success">
          <T phrase="signup" />
        </Pill>
      </StyledLink>
    </LoggedOutWrap>
  </HeaderWrap>
);

type HeaderProps = {
  authenticated: boolean;
  delayOverwrite?: number;
  hideHeaderOnMobileLandscape?: boolean;
  disableMenu?: boolean;
  displayDepositButton?: boolean;
  storybookDisabled?: boolean;
};

const Header = ({
  authenticated,
  delayOverwrite,
  hideHeaderOnMobileLandscape,
  disableMenu,
  displayDepositButton = false,
  storybookDisabled,
}: HeaderProps) => {
  if (!authenticated) return <SignedOutLinks />;
  if (hideHeaderOnMobileLandscape) {
    return (
      <TwitchLandscape data-testid="twitch-landscape">
        <SignedInLinks
          delayOverwrite={delayOverwrite}
          displayDepositButton={displayDepositButton}
          storybookDisabled={storybookDisabled}
        />
      </TwitchLandscape>
    );
  }
  return (
    <SignedInLinks
      delayOverwrite={delayOverwrite}
      disableMenu={disableMenu}
      displayDepositButton={displayDepositButton}
    />
  );
};
export default Header;
