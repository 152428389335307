import React, { PropsWithChildren, createContext, useContext } from 'react';

export const AppContext = createContext<AppContextProps>({
  isApp: false,
  host: '',
});

type AppContextProps = {
  isApp: boolean;
  host: string;
};

export const AppProvider = ({
  children,
  isApp,
  host,
}: PropsWithChildren<AppContextProps>) => {
  return (
    <AppContext.Provider value={{ isApp, host }}>
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
