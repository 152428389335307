import { darken, transparentize, rgb, parseToRgb } from 'polished';
import Palette from './colors';
import Brands from './brands';
import ThirdPartyBrandColors from './third-party-brand-colors';
import spacing from './spacing';
import games from './games';

const fontFamily =
  "'Plus Jakarta Sans', 'Montserrat','Helvetica Neue',Helvetica,Arial,sans-serif";

const common = {
  depth: {
    BOTTOM_BAR: 1000000,
    SIDEBAR: 2000000,
    MODAL: 3000000,
    DROPDOWN: 4000000,
    TOOLTIP: 9000000,
    TOAST: 10000000,
    BRAZE: 90000000,
  },
  spacing,
  games,
};

export type GlobalColors =
  | 'success'
  | 'information'
  | 'error'
  | 'warning'
  | 'unrated'
  | 'muchEasier'
  | 'easier'
  | 'balanced'
  | 'challenging'
  | 'veryChallenging'
  | 'offer'
  | 'winner'
  | 'greenPayout'
  | 'bluePayout'
  | 'goldPayout'
  | 'purplePayout';

type GlobalColor = {
  background: Palette;
  text: Palette;
  highlight: Palette;
};

export const globalColors: Record<GlobalColors, GlobalColor> = {
  success: {
    background: Palette.green,
    text: Palette.white,
    highlight: Palette.greenHighlight,
  },
  information: {
    background: Palette.blue,
    text: Palette.white,
    highlight: Palette.blueHighlight,
  },
  error: {
    background: Palette.red,
    text: Palette.white,
    highlight: Palette.redFlamingo,
  },
  warning: {
    background: Palette.yellow,
    text: Palette.blueNavy,
    highlight: Palette.goldHighlight,
  },
  unrated: {
    background: Palette.blueNavy,
    text: Palette.white,
    highlight: Palette.greenMint,
  },
  muchEasier: {
    background: Palette.purple,
    text: Palette.white,
    highlight: Palette.purpleLavender,
  },
  easier: {
    background: Palette.green,
    text: Palette.white,
    highlight: Palette.greenMint,
  },
  balanced: {
    background: Palette.blue,
    text: Palette.white,
    highlight: Palette.blueSky,
  },
  challenging: {
    background: Palette.orange,
    text: Palette.white,
    highlight: Palette.orangeCoral,
  },
  veryChallenging: {
    background: Palette.red,
    text: Palette.white,
    highlight: Palette.redFlamingo,
  },
  offer: {
    background: Palette.purple,
    text: Palette.white,
    highlight: Palette.purple,
  },
  winner: {
    background: Palette.yellowButter,
    text: Palette.white,
    highlight: Palette.yellowButter,
  },
  greenPayout: {
    background: Palette.greenPine,
    text: Palette.white,
    highlight: Palette.greenHighlight,
  },
  bluePayout: {
    background: Palette.blue,
    text: Palette.white,
    highlight: Palette.blueHighlight,
  },
  goldPayout: {
    background: Palette.yellowGold,
    text: Palette.white,
    highlight: Palette.goldHighlight,
  },
  purplePayout: {
    background: Palette.purple,
    text: Palette.white,
    highlight: Palette.purpleHighlight,
  },
};

const lightTheme = {
  ...common,
  palette: {
    background: {
      primary: Palette.blue,
      secondary: Palette.white,
      alternative: Palette.blueNavy,
      secondaryAlternative: Palette.blueWhite,
      tag: Palette.blueSlate,
      twitch: Brands.twitchPurple,
      infoBox: Palette.blueDenim,
      featuredTournament: Palette.yellowGold,
    },
    headings: {
      primary: Palette.blueNavy,
      primaryAlternative: Palette.blueSlate,
      secondary: Palette.white,
      secondaryAlternative: Palette.blueGrey,
      alternative: Palette.blue,
      featuredTournament: Palette.yellowGold,
    },
    text: {
      primary: Palette.blueNavy,
      secondary: Palette.blue,
      alternative: Palette.white,
      header: Palette.blueSlate,
      link: Palette.blue,
      highlight: Palette.purple,
      twitch: Brands.twitchPurple,
      featuredTournament: Palette.yellowGold,
      featuredTournamentAlternative: Palette.blueInk,
      dailyDollar: Palette.yellowGold,
    },
    input: {
      background: {
        primary: Palette.blueWhite,
        secondary: Palette.white,
      },
      highlighted: Palette.blue,
      label: Palette.blueNavy,
      text: Palette.blueNavy,
      textLabel: Palette.blueSlate,
      textGrey: transparentize('0.5', Palette.blueSlate),
      active: Palette.blueDenim,
    },
    border: {
      light: Palette.blueGrey,
      medium: transparentize('0.5', Palette.blueSlate),
      base: Palette.blue,
      dark: Palette.black,
      alternative: Palette.white,
      featuredTournament: Palette.yellowGold,
    },
    success: {
      background: Palette.green,
      text: Palette.white,
      active: darken('0.1', Palette.green),
    },
    popular: {
      background: Palette.purple,
      text: Palette.white,
      active: darken('0.1', Palette.purple),
    },
    error: {
      background: Palette.red,
      text: Palette.white,
      active: darken('0.1', Palette.red),
    },
    warning: {
      background: Palette.red,
      text: Palette.white,
      active: darken('0.1', Palette.red),
    },
    info: {
      background: Palette.blue,
      text: Palette.white,
      active: darken('0.1', Palette.blue),
    },
    infoInverted: {
      background: Palette.white,
      text: Palette.blue,
      active: darken('0.1', Palette.white),
    },
    openBtn: {
      background: Palette.white,
      text: Palette.blueNavy,
      active: darken('0.1', Palette.white),
    },
    plr: {
      na: {
        background: Palette.blueSlate,
        text: Palette.white,
      },
      1: {
        background: Palette.red,
        text: Palette.white,
      },
      2: {
        background: Palette.orange,
        text: Palette.white,
      },
      3: {
        background: Palette.yellow,
        text: Palette.white,
      },
      4: {
        background: Palette.green,
        text: Palette.white,
      },
      5: {
        background: Palette.greenPine,
        text: Palette.white,
      },
      goodMatchup: {
        background: Palette.blue,
        text: Palette.white,
      },
      challengingMatchup: {
        background: Palette.orange,
        text: Palette.white,
      },
      highlySkilled: {
        background: Palette.purpleGrape,
        text: Palette.white,
      },
      slightlyHigherSkilled: {
        background: Palette.orange,
        text: Palette.white,
      },
      slightlyLowerSkilled: {
        background: Palette.greenPine,
        text: Palette.white,
      },
      lowSkilled: {
        background: Palette.redCherry,
        text: Palette.white,
      },
    },
    createMatch: {
      modal: {
        background: Palette.white,
        header: Palette.blueNavy,
        fineprint: Palette.blueSlate,
        footer: {
          background: Palette.white,
        },
      },
    },
    avatar: {
      background: {
        primary: Palette.blueSlate,
        money: Palette.yellowGold,
        winner: Palette.yellowGold,
        ready: transparentize('0.35', Palette.green),
        cancel: transparentize('0.35', Palette.red),
        yellow: Palette.yellow,
      },
    },
    matchCardPlr: {
      default: {
        background: Palette.black,
      },
      1: {
        background: Palette.red,
      },
      2: {
        background: Palette.orange,
      },
      3: {
        background: Palette.yellow,
      },
      4: {
        background: Palette.green,
      },
      5: {
        background: Palette.greenPine,
      },
      blue: {
        background: Palette.blueSlate,
      },
      transparent: {
        background: 'transparent',
      },
      border: {
        light: Palette.white,
        dark: Palette.blueNavy,
      },
    },
    misc: {
      bankCard: {
        background: Palette.blueSlate,
        icon: Palette.white,
      },
      online: '#00e300',
      overlay: Palette.blueSlate,
      winner: {
        background: Palette.green,
        text: Palette.white,
      },
      admin: {
        background: Palette.blue,
        text: Palette.white,
      },
      joined: {
        background: Palette.green,
        text: Palette.white,
      },
      locked: {
        background: Palette.greenPine,
        text: Palette.white,
      },
      tournamentJoined: {
        background: Palette.blueGrey,
        text: Palette.blueNavy,
      },
      full: {
        background: Palette.white,
        text: Palette.white,
      },
      fullFeatured: {
        background: Palette.red,
        text: Palette.white,
      },
      stars: {
        color: Palette.yellow,
      },
      unverified: {
        background: Palette.yellow,
        text: Palette.black,
      },
      popular: {
        background: Palette.purple,
        alternativeBackground: Palette.white,
        text: Palette.white,
        alternativeText: Palette.purple,
      },
      icon: {
        light: Palette.white,
        lightBlue: Palette.blue,
        blue: Palette.blueSlate,
        orange: Palette.orange,
        red: Palette.red,
        darkBlue: Palette.blueNavy,
        green: Palette.green,
        grey: transparentize('0.5', Palette.blueSlate),
        greyWhite: transparentize('0.75', Palette.blueSlate),
      },
      earnCash: {
        background: Palette.purple,
        text: Palette.white,
      },
      depositValueHighlight: Palette.green,
      wonText: {
        background: Palette.yellow,
        text: Palette.orange,
      },
    },
    bigMoneyMatch: {
      border: Palette.yellowGold,
    },
    match: {
      border: Palette.blueGrey,
    },
  },
  switcher: {
    on: rgb(parseToRgb(Palette.blue)),
    off: rgb(parseToRgb(Palette.blueGrey)),
  },
  thirdParty: {
    facebook: ThirdPartyBrandColors.facebook,
    twitch: ThirdPartyBrandColors.twitch,
  },
  body: Palette.white,
  modalOverlay: 'rgba(0, 0, 0, 0.80)',
  shadow: transparentize('0.75', Palette.blueNavy),
  background: '#ebebeb',
  text: {
    primary: Palette.black,
    secondary: Palette.white,
  },
  link: {
    default: Palette.blueSlate,
    hover: Palette.blueSlate,
  },
  carouselControl: {
    dark: Palette.blueSlate,
    light: Palette.white,
  },
  checkbox: {
    default: Palette.blueGrey,
    active: Palette.blue,
    selected: Palette.blue,
  },
  hr: {
    background: Palette.blueGrey,
  },
  messaging: {
    notificationBG: Palette.blue,
    notificationText: Palette.white,
  },
  brand: {
    primary: Palette.blue,
    secondary: Palette.blueSlate,
  },
  feeSelector: {
    background: Palette.blue,
    highlight: transparentize('0.7', Palette.blue),
  },
  instantMatch: {
    primary: Palette.blueDenim,
    secondary: Palette.white,
    darkerBlue: Palette.blueNavy,
    goldGradient: Palette.yellowGold,
    blueGradientLight: '#0f3046',
    blueGradientDark: '#04111a',
    find: {
      lines: Palette.blueGrey,
      primary: Palette.blueSlate,
    },
    amount: {
      primary: Palette.white,
      secondary: Palette.blueNavy,
    },
    badge: {
      primary: Palette.blueSlate,
      secondary: Palette.white,
    },
  },
  openChallenge: {
    otherCard: {
      border: Palette.yellowGold,
      background: Palette.blueInk,
    },
    ownCard: {
      border: Palette.blue,
      background: Palette.white,
    },
  },
  dropdown: {
    item: {
      text: Palette.blueNavy,
      selected: Palette.blueWhite,
      border: Palette.blueGrey,
      icon: Palette.blueSlate,
    },
  },
  lobbyPicker: {
    selector: Palette.white,
  },
  card: {
    border: {
      green: Palette.green,
      blue: Palette.blue,
      red: Palette.red,
      orange: Palette.orange,
    },
  },
  sidebar: {
    contentBackground: Palette.blueWhite,
    headerColor: Palette.white,
    closeHover: Palette.blueGrey,
  },
  slideshow: {
    position: {
      mobile: {
        selected: Palette.blue,
        unselected: transparentize('0.5', Palette.blue),
      },
      desktop: {
        selected: Palette.white,
        unselected: transparentize('0.5', Palette.white),
      },
    },
  },
  onboarding: {
    noteText: Palette.blueNavy,
    helpText: Palette.blueSlate,
    linkText: Palette.blue,
    memberPerkBadgeText: Palette.white,
    memberPerkText: Palette.blueDenim,
  },
  tournament: {
    text: Palette.blueNavy,
    background: Palette.white,
    featuredBackground: Palette.blueInk,
  },
  chat: {
    background: Palette.blueWhite,
    sentMessageBackground: Palette.blueSlate,
    receivedMessageBackground: Palette.blueDenim,
    receivedAdminMessageBackground: Palette.white,
    foreground: Palette.white,
    foregroundAdmin: Palette.blueNavy,
    separator: Palette.blueGrey,
  },
  modal: {
    default: Palette.white,
    dark: Palette.blueSlate,
  },
  header: Palette.blueNavy,
  title: '#349dd6',
  subtitle: '#8c8b8b',
  navigationLink: Palette.white,
  overlay: Palette.blueSlate,
  activeUser: Palette.green,
  tableHeader: '#8c8b8b',
  tableHeaderBG: '#f3f3f3',
  onlineStatus: Palette.green,
  font: fontFamily,
  gamesList: {
    background: Palette.blueGrey,
  },
  platform: {
    paypal: '#f6bb43',
    skrill: '#602467',
    visa: '#2c2d7e',
    mastercard: '#000000',
    amex: '#026fd0',
  },
  bankCard: {
    selected: Palette.blue,
  },
  twitchStream: {
    defaultBackground: Palette.blueInk,
    color: Palette.white,
    noStreamColor: Palette.blueSlate,
  },
  plPlus: {
    gradientStart: Brands.plPlus.sunlitAmber,
    gradientMiddle: Brands.plPlus.crimsonBlossom,
    gradientEnd: Brands.plPlus.midnightIndigo,
    gradientFull: [
      Brands.plPlus.sunlitAmber,
      Brands.plPlus.crimsonBlossom,
      Brands.plPlus.midnightIndigo,
    ],
  },
  new: {
    theme: 'light',
    background: Palette.blueWhite,
    surface: Palette.white,
    content: Palette.blueDenim,
    subContent: Palette.blueSlate,
    divisions: Palette.blueGrey,
    ...globalColors,
  },
};

const darkTheme = {
  ...lightTheme,
  new: {
    theme: 'dark',
    background: Palette.blueInk,
    surface: Palette.blueNavy,
    content: Palette.white,
    subContent: Palette.blueGrey,
    divisions: Palette.blueDenim,
    ...globalColors,
  },
};

export type Themes = 'light' | 'dark';

const themeObject = {
  light: lightTheme,
  dark: darkTheme,
};

export type Theme = typeof lightTheme;
const getTheme = (theme: Themes): Theme => {
  return themeObject[theme] ? themeObject[theme] : lightTheme;
};

export default getTheme;
export { lightTheme, darkTheme, getTheme };
