const formatCurrency = (
  {
    currency,
    value,
  }: { currency?: string | null; value?: string | number | null },
  removeCurrency = false,
  isPlc = false,
) => {
  if (value === 0) return 'Free';
  // checking against LC; if currency is null, defaults to $
  const currencySymbol = currency === 'LC' && isPlc ? currency : '$';

  const currencyIncluded = removeCurrency ? '' : currencySymbol;
  const parsedValue = Number.parseFloat(`${value}` || '0');
  if (Number.isNaN(parsedValue)) {
    throw Error('Unable to parse string as numerical value');
  }

  if (isPlc && currency === 'LC') {
    return `${(parsedValue || 0) * 100} ${currencySymbol}`;
  }

  if (parsedValue % 1 !== 0) {
    return `${currencyIncluded}${parsedValue.toFixed(2)}`;
  }

  return `${currencyIncluded}${value}`;
};

export default formatCurrency;
