// eslint-disable-next-line no-use-before-define
import React from 'react';
import styled, { ThemeProvider, useTheme } from 'styled-components';
import Link from 'components/atoms/link';
import {
  fontXS,
  fontS,
  MIN_TABLET,
  darkTheme,
  lightTheme,
  Iconography,
  AvailableIcons,
} from 'components/styles';

type BalanceAmountType = {
  loungeCredits?: number;
};

const lcEnabled = process.env.LOUNGE_CREDITS_ENABLED === 'true';

const BalanceAmount = styled.span<BalanceAmountType>`
  ${fontXS}
  color: ${({ theme }) => theme.new.content}};
  transition: 0.25s;
  ${({ loungeCredits }) =>
    !loungeCredits &&
    (loungeCredits !== 0 || !lcEnabled) &&
    'padding-top: 5px;'}

  @media (min-width: ${MIN_TABLET}) {
    ${fontS}
    ${({ loungeCredits }) =>
      !loungeCredits &&
      (loungeCredits !== 0 || !lcEnabled) &&
      'padding-top: 0;'}
  }
`;

const LoungeCreditAmount = styled.span`
  ${fontXS}
  transition: 0.25s;
  color: ${({ theme }) => theme.new.content};
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: ${MIN_TABLET}) {
    ${fontS}
  }
`;

type BalanceWrapperType = {
  isSelected?: boolean;
};

const BalanceWrapper = styled(Link)<BalanceWrapperType>`
  padding-left: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: flex-start;

  color: ${({ theme }) => theme.new.content}};
  fill:  ${({ theme }) => theme.new.content}};
  transition: 0.25s;

  @media (min-width: ${MIN_TABLET}) {
    flex-direction: row;
  }
`;

const BalanceAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 6px;

  @media (min-width: ${MIN_TABLET}) {
    margin-left: 5px;
    display: inline-block;
    align-items: flex-start;
  }
`;

const LCIcon = styled(Iconography)`
  width: 12px;
  height: 12px;

  @media (min-width: ${MIN_TABLET}) {
    width: 16px;
    height: 16px;
  }
`;

const AddBalanceIcon = styled(Iconography)`
  align-self: flex-start;
  padding-top: 3px;
  transition: 0.25s;
  color: ${({ theme }) => theme.new.content}};
  fill:  ${({ theme }) => theme.new.content}};

  @media (min-width: ${MIN_TABLET}) {
    align-self: center;
    padding-top: 0;
  }
`;

export type UserBalanceType = {
  currency: string;
  value: string;
  loungeCredits?: number | null | undefined;
  themeOverwrite?: 'dark' | 'light';
};

// Inspired by https://stackoverflow.com/questions/2685911/is-there-a-way-to-round-numbers-into-a-reader-friendly-format-e-g-1-1k
const abbreviateNumber = (
  numberPassed: number,
  decimalPlacesMax: number,
): string => {
  let abbreviation = '';
  let number = numberPassed;

  const abbreviations = ['k', 'm', 'b', 't'];
  const decPlaces = 10 ** decimalPlacesMax;

  for (let i = abbreviations.length - 1; i >= 0; i -= 1) {
    const numberSizeFromIndex = 10 ** ((i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (numberSizeFromIndex <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number =
        Math.round((number * decPlaces) / numberSizeFromIndex) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbreviations.length - 1) {
        number = 1;
        i += 1;
      }
      abbreviation = abbreviations[i];
      break;
    }
  }
  return `${number}${abbreviation}`;
};

export const UserBalance = ({
  currency,
  value,
  loungeCredits = 0,
  themeOverwrite,
}: UserBalanceType): JSX.Element => {
  const globalTheme = useTheme();
  const overwrittenTheme = themeOverwrite === 'dark' ? darkTheme : lightTheme;
  const formatCommaEveryThreeNumbers = /\d(?=(\d{3})+\.)/g;

  const decimalPlaces = 2;

  const formattedValue = parseFloat(value)
    ? parseFloat(value)
        .toFixed(decimalPlaces)
        .replace(formatCommaEveryThreeNumbers, '$&,')
    : '0.00';

  return (
    <ThemeProvider theme={themeOverwrite ? overwrittenTheme : globalTheme}>
      <BalanceWrapper data-testid="user-balance" href="/deposit?ds=navbalance">
        <AddBalanceIcon name={AvailableIcons.WALLET} />
        <BalanceAmountWrapper>
          <BalanceAmount loungeCredits={loungeCredits || 0}>
            {currency}
            {formattedValue}
          </BalanceAmount>
          {lcEnabled && (
            <LoungeCreditAmount>
              <LCIcon name={AvailableIcons.LOUNGE_CREDITS} />
              &nbsp;
              {abbreviateNumber(loungeCredits || 0, 2)}
            </LoungeCreditAmount>
          )}
        </BalanceAmountWrapper>
      </BalanceWrapper>
    </ThemeProvider>
  );
};
