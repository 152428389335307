import styled, { DefaultTheme } from 'styled-components';

import {
  DISABLED_STATE,
  fontS,
  fontM,
  BASE_SPACING_AND_HALF,
  BASE_SPACING,
  customSpacing,
  HALF_BASE_SPACING,
  QUARTER_BASE_SPACING,
  DOUBLE_BASE_SPACING,
  fontXXS,
} from 'components/styles';
import { LabelPalette, LabelSizing, LabelVariant, LabelType } from './label';

export const colors = (
  theme: DefaultTheme,
): Record<LabelVariant, Record<LabelType, LabelPalette>> => ({
  outline: {
    default: {
      border: theme.new.divisions,
      background: 'transparent',
      text: theme.new.subContent,
      icon: theme.new.subContent,
    },
    success: {
      border: theme.new.success.background,
      background: 'transparent',
      text: theme.new.success.background,
      icon: theme.new.success.background,
    },
    information: {
      border: theme.new.information.background,
      background: 'transparent',
      text: theme.new.information.background,
      icon: theme.new.information.background,
    },
    warning: {
      border: theme.new.warning.background,
      background: 'transparent',
      text: theme.new.warning.background,
      icon: theme.new.warning.background,
    },
    error: {
      border: theme.new.error.background,
      background: 'transparent',
      text: theme.new.error.background,
      icon: theme.new.error.background,
    },
    unrated: {
      border: theme.new.unrated.highlight,
      background: theme.new.unrated.background,
      text: theme.new.unrated.text,
      icon: theme.new.unrated.highlight,
    },
    muchEasier: {
      border: theme.new.muchEasier.highlight,
      background: theme.new.muchEasier.background,
      text: theme.new.muchEasier.text,
      icon: theme.new.muchEasier.text,
    },
    easier: {
      border: theme.new.easier.highlight,
      background: theme.new.easier.background,
      text: theme.new.easier.text,
      icon: theme.new.easier.text,
    },
    balanced: {
      border: theme.new.balanced.highlight,
      background: theme.new.balanced.background,
      text: theme.new.balanced.text,
      icon: theme.new.balanced.text,
    },
    challenging: {
      border: theme.new.challenging.highlight,
      background: theme.new.challenging.background,
      text: theme.new.challenging.text,
      icon: theme.new.challenging.text,
    },
    veryChallenging: {
      border: theme.new.veryChallenging.highlight,
      background: theme.new.veryChallenging.background,
      text: theme.new.veryChallenging.text,
      icon: theme.new.veryChallenging.text,
    },
    new: {
      border: theme.new.winner.highlight,
      background: theme.new.unrated.background,
      text: theme.new.winner.highlight,
      icon: theme.new.winner.highlight,
    },
  },
  subtle: {
    default: {
      border: theme.new.surface,
      background:
        theme.new.theme === 'dark' ? theme.new.background : theme.new.divisions,
      text: theme.new.content,
      icon: theme.new.content,
    },
    success: {
      border: theme.new.surface,
      background:
        theme.new.theme === 'dark' ? theme.new.background : theme.new.divisions,
      text: theme.new.content,
      icon: theme.new.success.background,
    },
    information: {
      border: theme.new.surface,
      background:
        theme.new.theme === 'dark' ? theme.new.background : theme.new.divisions,
      text: theme.new.content,
      icon: theme.new.information.background,
    },
    warning: {
      border: theme.new.surface,
      background:
        theme.new.theme === 'dark' ? theme.new.background : theme.new.divisions,
      text: theme.new.content,
      icon: theme.new.warning.background,
    },
    error: {
      border: theme.new.surface,
      background:
        theme.new.theme === 'dark' ? theme.new.background : theme.new.divisions,
      text: theme.new.content,
      icon: theme.new.error.background,
    },
    new: {
      border: theme.new.winner.highlight,
      background: theme.new.unrated.background,
      text: theme.new.winner.highlight,
      icon: theme.new.winner.highlight,
    },
    unrated: {
      border: theme.new.unrated.highlight,
      background: theme.new.unrated.background,
      text: theme.new.unrated.text,
      icon: theme.new.unrated.highlight,
    },
    muchEasier: {
      border: theme.new.muchEasier.highlight,
      background: theme.new.muchEasier.background,
      text: theme.new.muchEasier.text,
      icon: theme.new.muchEasier.text,
    },
    easier: {
      border: theme.new.easier.highlight,
      background: theme.new.easier.background,
      text: theme.new.easier.text,
      icon: theme.new.easier.text,
    },
    balanced: {
      border: theme.new.balanced.highlight,
      background: theme.new.balanced.background,
      text: theme.new.balanced.text,
      icon: theme.new.balanced.text,
    },
    challenging: {
      border: theme.new.challenging.highlight,
      background: theme.new.challenging.background,
      text: theme.new.challenging.text,
      icon: theme.new.challenging.text,
    },
    veryChallenging: {
      border: theme.new.veryChallenging.highlight,
      background: theme.new.veryChallenging.background,
      text: theme.new.veryChallenging.text,
      icon: theme.new.veryChallenging.text,
    },
  },
  contrast: {
    default: {
      border: theme.new.background,
      background:
        theme.new.theme === 'dark' ? theme.new.subContent : theme.new.content,
      text: theme.new.background,
      icon: theme.new.background,
    },
    information: {
      border: theme.new.information.background,
      background: theme.new.information.background,
      text: theme.new.information.text,
      icon: theme.new.information.text,
    },
    success: {
      border: theme.new.success.background,
      background: theme.new.success.background,
      text: theme.new.success.text,
      icon: theme.new.success.text,
    },
    warning: {
      border: theme.new.warning.background,
      background: theme.new.warning.background,
      text: theme.new.warning.text,
      icon: theme.new.warning.text,
    },
    error: {
      border: theme.new.error.background,
      background: theme.new.error.background,
      text: theme.new.error.text,
      icon: theme.new.error.text,
    },
    new: {
      border: theme.new.winner.highlight,
      background: theme.new.unrated.background,
      text: theme.new.winner.highlight,
      icon: theme.new.winner.highlight,
    },
    unrated: {
      border: theme.new.unrated.highlight,
      background: theme.new.unrated.background,
      text: theme.new.unrated.text,
      icon: theme.new.unrated.highlight,
    },
    muchEasier: {
      border: theme.new.muchEasier.highlight,
      background: theme.new.muchEasier.background,
      text: theme.new.muchEasier.text,
      icon: theme.new.muchEasier.text,
    },
    easier: {
      border: theme.new.easier.highlight,
      background: theme.new.easier.background,
      text: theme.new.easier.text,
      icon: theme.new.easier.text,
    },
    balanced: {
      border: theme.new.balanced.highlight,
      background: theme.new.balanced.background,
      text: theme.new.balanced.text,
      icon: theme.new.balanced.text,
    },
    challenging: {
      border: theme.new.challenging.highlight,
      background: theme.new.challenging.background,
      text: theme.new.challenging.text,
      icon: theme.new.challenging.text,
    },
    veryChallenging: {
      border: theme.new.veryChallenging.highlight,
      background: theme.new.veryChallenging.background,
      text: theme.new.veryChallenging.text,
      icon: theme.new.veryChallenging.text,
    },
  },
});

const typeography: Record<LabelSizing, string> = {
  large: fontM,
  medium: fontS,
  small: fontXXS,
};

const lineHeight: Record<LabelSizing, string> = {
  large: BASE_SPACING_AND_HALF,
  medium: BASE_SPACING,
  small: customSpacing('12px'),
};

export const iconSizeMap: Record<LabelSizing, number> = {
  large: 24,
  medium: 16,
  small: 12,
};

const borderRadiusMap: Record<LabelSizing, string> = {
  large: BASE_SPACING,
  medium: customSpacing('12px'),
  small: HALF_BASE_SPACING,
};

const sizing: Record<
  LabelSizing,
  {
    height: string;
    padding: string;
    iconPadding: string;
    iconPaddingRight: string;
    iconPaddingLeft: string;
  }
> = {
  large: {
    height: DOUBLE_BASE_SPACING,
    padding: HALF_BASE_SPACING,
    iconPadding: QUARTER_BASE_SPACING,
    iconPaddingRight: QUARTER_BASE_SPACING,
    iconPaddingLeft: QUARTER_BASE_SPACING,
  },
  medium: {
    height: BASE_SPACING_AND_HALF,
    padding: customSpacing('6px'),
    iconPadding: customSpacing('2px'),
    iconPaddingRight: customSpacing('2px'),
    iconPaddingLeft: customSpacing('2px'),
  },
  small: {
    height: BASE_SPACING,
    padding: QUARTER_BASE_SPACING,
    iconPadding: customSpacing('1px'),
    iconPaddingRight: customSpacing('2px'),
    iconPaddingLeft: customSpacing('2px'),
  },
};

export const LabelWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
`;

export const IconWrapper = styled.div<{
  size: LabelSizing;
  startIcon?: boolean;
  endIcon?: boolean;
  hasContent: boolean;
}>`
  display: flex;
  margin-right: ${({ size, startIcon, hasContent }) =>
    startIcon && hasContent ? sizing[size].iconPaddingRight : '0px'};
  margin-left: ${({ size, endIcon, hasContent }) =>
    endIcon && hasContent ? sizing[size].iconPaddingLeft : '0px'};
`;

export const LabelText = styled.p<{
  size: LabelSizing;
  styling: LabelPalette;
  startIcon: boolean;
  endIcon: boolean;
}>`
  color: ${({ styling }) => styling.text};
  ${({ size }) => typeography[size]};
  line-height: ${({ size }) => lineHeight[size]};
`;

export const Container = styled.div<{
  size: LabelSizing;
  disabled: boolean;
  styling: LabelPalette;
  variant: LabelVariant;
  startIcon: boolean;
  endIcon: boolean;
  hasContent: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  height: ${({ size }) => sizing[size].height};
  width: ${({ size, hasContent }) =>
    hasContent ? 'auto' : sizing[size].height};
  padding-left: ${({ size, startIcon, hasContent }) =>
    startIcon || !hasContent ? sizing[size].iconPadding : sizing[size].padding};
  padding-right: ${({ size, endIcon, hasContent }) =>
    endIcon || !hasContent ? sizing[size].iconPadding : sizing[size].padding};
  background-color: ${({ styling }) => styling.background};
  border-color: ${({ styling }) => styling.border};
  border-width: 1px;
  border-style: solid;
  border-radius: ${({ size }) => borderRadiusMap[size]};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  ${DISABLED_STATE}
`;
