import React from 'react';
import styled, { useTheme } from 'styled-components';
import {
  Iconography,
  AvailableIcons,
  fontXXS,
  fontXS,
  fontS,
  fontL,
  font2XL,
  font3XL,
} from 'components/styles';
import Palette from 'components/styles/colors';
import {
  MatchCompetitorFragment,
  UserAvailabilityStatus,
  UserFragment,
} from 'graphpl/core';
import { NewRelativeSkill, NewRelativeSkillProps } from '../new-relative-skill';

const hashUsername = (username: string): number => {
  return Math.abs(
    username?.split('').reduce(
      (prevHash, val) => ((prevHash << 5) - prevHash + val.charCodeAt(0)) | 0, // eslint-disable-line no-bitwise
      0,
    ) % 6,
  );
};

const backGroundMap = [
  Palette.blueDenim,
  Palette.greenPine,
  Palette.yellowHoney,
  Palette.orangeRust,
  Palette.redCherry,
  Palette.purpleGrape,
];

export type AvatarSize =
  | 'extraSmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'extraLarge'
  | 'extraExtraLarge';

type AvatarType = {
  size: AvatarSize;
  user: MatchCompetitorFragment | UserFragment | null;
  plr?: NewRelativeSkillProps;
  disableActivityDot?: boolean;
  displayOffline?: boolean;
};

type WrapperType = {
  size: number;
  fontSize: string;
};

export const wrapperSizes: Record<AvatarSize, WrapperType> = {
  extraSmall: {
    size: 16,
    fontSize: fontXXS,
  },
  small: { size: 24, fontSize: fontXS },
  medium: { size: 32, fontSize: fontS },
  large: { size: 48, fontSize: fontL },
  extraLarge: { size: 64, fontSize: font2XL },
  extraExtraLarge: { size: 80, fontSize: font3XL },
};

const AvatarContainer = styled.div<{ size: AvatarSize }>`
  display: flex;
  box-sizing: border-box;
  background-color: transparent;
  height: ${({ size }) => wrapperSizes[size].size}px;
  width: ${({ size }) => wrapperSizes[size].size}px;
  min-height: ${({ size }) => wrapperSizes[size].size}px;
  min-width: ${({ size }) => wrapperSizes[size].size}px;
  max-height: ${({ size }) => wrapperSizes[size].size}px;
  max-width: ${({ size }) => wrapperSizes[size].size}px;
  position: relative;
`;

const PLRWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  align-items: center;
  justify-content: center;
  display: flex;
`;

// margin-right: ${({ theme, size }) =>
//   theme.spacing.customSpacing(dotPosition[size])};
// margin-top: ${({ theme, size }) =>
//   theme.spacing.customSpacing(dotPosition[size])};

export const dotPosition: Record<
  AvatarSize,
  { size: number; inset: number }
> = {
  extraSmall: {
    size: 4,
    inset: 0,
  },
  small: { size: 6, inset: 0 },
  medium: { size: 8, inset: 0 },
  large: { size: 12, inset: 0 },
  extraLarge: { size: 12, inset: 2 },
  extraExtraLarge: { size: 12, inset: 4 },
};

const AvatarActiveDot = styled.div<{ size: AvatarSize }>`
  position: absolute;
  top: ${({ theme, size }) =>
    theme.spacing.customSpacing(dotPosition[size].inset)};
  right: ${({ theme, size }) =>
    theme.spacing.customSpacing(dotPosition[size].inset)};
  background-color: ${({ theme }) => theme.new.success.background};
  border: ${({ theme }) => `1px solid ${theme.new.success.highlight}`};
  border-radius: 50%;
  height: ${({ theme, size }) =>
    theme.spacing.customSpacing(dotPosition[size].size)};
  width: ${({ theme, size }) =>
    theme.spacing.customSpacing(dotPosition[size].size)};
`;

const Wrapper = styled.div<{
  size: AvatarSize;
  username: string;
  offline: boolean;
}>`
  display: flex;
  box-sizing: border-box;
  background-color: ${({ username }) => backGroundMap[hashUsername(username)]};
  height: ${({ size }) => wrapperSizes[size].size}px;
  width: ${({ size }) => wrapperSizes[size].size}px;
  border-radius: ${({ size }) => wrapperSizes[size].size / 2}px;
  border-color: ${({ theme }) => theme.new.divisions};
  border-width: 1px;
  border-style: solid;
  justify-content: center;
  align-items: center;
`;

const AvatarImage = styled.img<{ size: AvatarSize }>`
  border-radius: ${({ size }) => wrapperSizes[size].size / 2}px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.new.background};
  height: ${({ size }) => wrapperSizes[size].size}px;
  width: ${({ size }) => wrapperSizes[size].size}px;
  min-height: ${({ size }) => wrapperSizes[size].size}px;
  min-width: ${({ size }) => wrapperSizes[size].size}px;
  max-height: ${({ size }) => wrapperSizes[size].size}px;
  max-width: ${({ size }) => wrapperSizes[size].size}px;
`;

const FirstLetter = styled.p<{ size: AvatarSize }>`
  color: ${({ theme }) => theme.new.information.text};
  ${({ size }) => wrapperSizes[size].fontSize};
  text-align: center;
  padding-top: 1px;
`;

const WrapperBlue = styled(Wrapper)`
  background-color: ${({ theme }) => theme.new.information.background};
`;

export const Avatar = ({
  size,
  user,
  plr,
  disableActivityDot = false,
  displayOffline = true,
}: AvatarType) => {
  const username =
    user?.username ||
    // @ts-ignore
    user?.name ||
    '';
  const isActive = user?.status?.availability === UserAvailabilityStatus.ACTIVE;
  const isOffline =
    user?.status?.availability === UserAvailabilityStatus.OFFLINE &&
    displayOffline;
  const unsetAvatar = !user?.avatar || user?.avatar?.includes('outline.png');
  return (
    <AvatarContainer size={size}>
      <Wrapper size={size} username={username} offline={isOffline}>
        {!unsetAvatar ? (
          <AvatarImage size={size} src={user.avatar} alt={username} />
        ) : (
          <FirstLetter size={size}>
            {username?.charAt(0)?.toUpperCase()}
          </FirstLetter>
        )}
      </Wrapper>
      <PLRWrapper>
        {plr && <NewRelativeSkill {...plr} opponent={user} />}
      </PLRWrapper>
      {isActive && !disableActivityDot && <AvatarActiveDot size={size} />}
    </AvatarContainer>
  );
};

export const MaxUsersAvatar = ({
  size,
  userCount,
}: {
  size: AvatarSize;
  userCount: number;
}) => {
  return (
    <WrapperBlue size={size} username={`${userCount}`} offline={false}>
      <FirstLetter size={size}>+{userCount}</FirstLetter>
    </WrapperBlue>
  );
};

export const AddAvatar = ({ size }: { size: AvatarSize }) => {
  const theme = useTheme();
  return (
    <WrapperBlue size={size} username="add-player" offline={false}>
      <Iconography
        name={AvailableIcons.ADD_PERSON}
        size={16}
        color={theme.new.information.text}
      />
    </WrapperBlue>
  );
};
