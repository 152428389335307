import { fontM, fontS, fontXL, TOUCHABLE_STYLE } from 'components/styles';
import styled from 'styled-components';

export const Wrapper = styled.div<{
  topPadding: number;
  bottomPadding: number;
}>`
  background-color: ${({ theme }) => theme.new.surface};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  padding-top: ${({ theme, topPadding }) =>
    theme.spacing.customSpacing(topPadding)};
  padding-bottom: ${({ theme, bottomPadding }) =>
    theme.spacing.customSpacing(bottomPadding)};
  scroll-behavior: auto;
  overflow-y: auto;
`;

export const SpinnerWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const InternalContent = styled.div`
  padding-top: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const Footer = styled.div<{ disabled: boolean }>`
  background-color: ${({ theme }) => theme.new.surface};
  padding-top: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  display: flex;
  flex-direction: column;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  position: relative;
  margin-top: 0px;
  border-top: 1px solid ${({ theme }) => theme.new.divisions};
`;

export const ErrorHeading = styled.h1`
  color: ${({ theme }) => theme.new.content};
  ${fontXL}
  padding-bottom: ${({ theme }) => theme.spacing.DOUBLE_BASE_SPACING};
`;

export const ErrorContent = styled.h1`
  color: ${({ theme }) => theme.new.content};
  ${fontM}
`;

export const RulesContent = styled.div`
  overflow-y: auto;
`;

export const FullRulesWrapper = styled.div`
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  overflow-y: auto;
  flex: 1;
  ${fontS}
  background-color: ${({ theme }) => theme.new.surface};
  color: ${({ theme }) => theme.new.content};

  p {
    margin-top: ${({ theme }) => theme.spacing.BASE_SPACING};
  }
  ul > li {
    list-style-type: disc;
    margin-left: ${({ theme }) => theme.spacing.customSpacing('18px')};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  ${fontXL};
  color: ${({ theme }) => theme.new.content};
  background-color: ${({ theme }) => theme.new.surface};
  padding-top: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING};
  padding-bottom: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  cursor: pointer;
  ${TOUCHABLE_STYLE}
`;
