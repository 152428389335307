export const DEPOSIT_PAGE = 'test-deposit_page';
export const PROFILE_PAGE = 'test-user_profile_page';
export const REFERRAL_PAGE = 'test-user_referral_page';

export const ADVERT_1_1 = 'advert_1_1';
export const ADVERT_2_1 = 'advert_2_1';
export const ADVERT_3_1 = 'advert_3_1';

// sources should be defined as <location>-<descriptor>
// sources should always be snake cased with a single - to differentiate between the location and descriptor
export const GAME_PICKER = 'game_picker';
export const GAME_PICKER_SELECTED = 'game_picker-selected';
export const CHAT_PROFILE = 'global_chat-profile';
export const LIVE_STREAM_CHAT_PROFILE = 'live_stream_chat-profile';
export const LIVE_STREAM_HEADER_CHALLENGE_BUTTON =
  'live_stream_header-challenge-button';
export const LIVE_STREAM_CHAT_STREAMER_PROFILE =
  'live_stream_chat-streamer_profile';

export const GLOBAL_HEADER_TOGGLE_DOWN_TO_PLAY =
  'global_header-toggle_down_to_play';
export const GLOBAL_HEADER_REFERRER_LINK = 'global_header-referrer_link';
export const GLOBAL_HEADER_VERIFICATION_RESEND =
  'global_header-resend_verification_email';

export const LOUNGE_FIND_MATCH_BUTTON = 'lounge_header-find_match_button';
export const LOUNGE_CHAT_BUTTON = 'lounge_header-chat_button';
export const LOUNGE_CHALLENGE_BUTTON = 'lounge_header-challenge_button';

export const DIRECT_MESSAGE_CHANNEL = 'direct_message-channel';

export const TWITCH_STREAM_CHAT_BUTTON = 'twitch_stream-chat_button';
export const TWITCH_STREAM_HEADER_PROFILE = 'twitch_stream-header_profile';
export const TWITCH_STREAM_H2H_STREAMER_PROFILE =
  'twitch_stream-h2h_streamer_profile';
export const TWITCH_STREAM_H2H_OPPONENT_PROFILE =
  'twitch_stream-h2h_opponent_profile';
export const TWITCH_STREAM_MATCH_LINK = 'twitch_stream-match_link';

export const ALL_STREAMS_CARD = 'all_streams-card';

export const LOUNGE_FIND_MATCH_CARD = 'lounge-find_match_card';
export const LOUNGE_ACTIVE_MATCH_CARD = 'lounge-active_match_card';
export const LOUNGE_INSTANT_MATCH_CARD = 'lounge-instant_match_card';
export const LOUNGE_SEARCHING_CARD = 'lounge-searching_card';
export const LOUNGE_STREAMER_CARD = 'lounge-steamer_card';
export const LOUNGE_MMP_LINK = 'lounge-most_played_players';
export const LOUNGE_DTP_LINK = 'lounge-down_to_play';
export const LOUNGE_OIL_LINK = 'lounge-online_in_lobby';
export const LOUNGE_DD_BANNER = 'lounge-daily_dollar_banner';
export const LOUNGE_REFERRER_BANNER = 'lounge-referrer_banner';
export const LOUNGE_ALL_TOURNAMENTS = 'lounge-all_tournaments';
export const LOUNGE_LINK_TWITCH = 'lounge-link_twitch';
export const LOUNGE_ALL_STREAMS = 'lounge-view_all_streams';
export const LOUNGE_INSTANT_MATCHES = 'lounge-view_instant_matches';

export const LOGIN_FORGOTTEN_PASSWORD = 'login-forgotten_password';
export const LOGIN_ATTEMPT = 'login-login_attempt';
export const LOGIN_COMPLETE = 'login-login_complete';

export const SIGNUP_ATTEMPT_REGISTRATION = 'signup-attempt_registration';
export const SIGNUP_COMPLETE_REGISTRATION = 'signup-complete_registration';
export const SIGNUP_ERROR_REGISTRATION = 'signup-error_registration';

export const ONBOARDING_VERIFICATION_RESEND =
  'onboarding-resend_verification_email';

export const ONBOARDING_SLIDESHOW_INTERACTION =
  'onboarding-slideshow_interaction';

export const DIRECT_MESSAGES_CLOSE_CREATE_CHAT =
  'direct-messages-close_create_chat';
export const DIRECT_MESSAGES_OPEN_SEND_CHALLENGE_MODAL_FROM_CHAT_AVATAR =
  'direct-messages-open_send_challenge_modal_from_chat_avatar';
export const DIRECT_MESSAGES_OPEN_SEND_CHALLENGE_MODAL_FROM_CHALLENGE_BUTTON =
  'direct-messages-open_send_challenge_modal_from_challenge_button';
export const DIRECT_MESSAGES_OPEN_SEND_CHALLENGE_MODAL_FROM_INPUT =
  'direct-messages-open_send_challenge_modal_from_input';
export const DIRECT_MESSAGES_OPEN_SEND_CHALLENGE_MODAL_FROM_LOBBY_AVATAR =
  'direct-messages-open_send_challenge_modal_from_lobby_avatar';
export const DIRECT_MESSAGES_OPEN_SEND_CHALLENGE_MODAL_FROM_MENU =
  'direct-messages-open_send_challenge_modal_from_menu';
export const DIRECT_MESSAGES_CREATE_CHAT = 'direct-messages-create_chat';
export const DIRECT_MESSAGES_CLOSE_CHAT = 'direct-messages-close_chat';
export const DIRECT_MESSAGES_BLOCK_USER = 'direct-messages-block_user';
export const DIRECT_MESSAGES_UNBLOCK_USER = 'direct-messages-unblock_user';
export const DIRECT_MESSAGES_GROUP_CHAT_SETTINGS =
  'direct-messages-group_chat_settings';
export const DIRECT_MESSAGES_ONE_TO_ONE_CHAT_SETTINGS =
  'direct-messages-one_to_one_chat_settings';
export const DIRECT_MESSAGES_GLOBAL_NAV_ELEMENT =
  'direct-messages-global_nav_element';

export const DEPOSIT_DAILY_DOLLAR_INFORMATION =
  'deposit-daily-dollar-information';
export const DEPOSIT_LOUNGE_CREDITS_INFORMATION =
  'deposit-lounge-credits-information';
export const DEPOSIT_SHOP_LINK = 'deposit-shop-link';
export const DEPOSIT_GO_TO_PAYMENT = 'deposit-go_to_payment';
export const DEPOSIT_SELECT_PAYMENT_METHOD = 'deposit-select_payment_method';
export const DEPOSIT_ADD_NEW_CARD = 'deposit-add_new_card';
export const DEPOSIT_VERIFY_CARD = 'deposit-verify_card';
export const DEPOSIT_ID_VERIFICATION_MODAL_OPEN =
  'deposit-id_verification_modal_open';
export const DEPOSIT_BERBIX_VERIFICATION_COMPLETE =
  'deposit-berbix_verification_complete';
export const DEPOSIT_COMPLETE = 'deposit-complete';

export const LINKED_ACCOUNTS_LINK_TWITCH = 'linked-accounts_twitch';
export const LINKED_ACCOUNTS_CHANGE_AVATAR = 'linked-accounts_change_avatar';
export const LINKED_ACCOUNTS_DISMISS_CHANGE_AVATAR =
  'linked-accounts_dismiss_change_avatar';

export const PROFILE_CHALLENGE_BUTTON = 'profile-challenge_button';
export const PROFILE_MATCH_LINK = 'profile-match_link';
export const PROFILE_CHAT_BUTTON = 'profile-chat_button';

export const SUBSCRIPTION_BUY_PAGE_CHOSE_SUBSCRIPTION_PLAN =
  'subscription_buy_page-chose_subcription_plan';
