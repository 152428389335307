import React, { useState } from 'react';
import { useUser } from 'components/util/user-context';
import {
  MatchCompetitorFragment,
  MatchContestFragment,
  useCancelInviteToChallengeMutation,
  useInviteToChallengeMutation,
} from 'graphpl/core';
import { Avatar } from 'components/atoms/new-avatar';
import { AvailableIcons, Iconography } from 'components/styles';
import LoadingSpinner from 'components/atoms/loading-spinner';
import { useTheme } from 'styled-components';
import {
  IconWrapper,
  LeftItems,
  OpponentName,
  PlPlusOpponentName,
  RowWrapper,
} from './invite-user-row.styles';

type InviteUserRowProps = {
  matchData: MatchContestFragment;
  opponent: MatchCompetitorFragment;
  storybookLoading?: boolean;
  storybookAlreadyInvited?: boolean;
};

const Icon = ({
  loading,
  alreadyInvited,
}: {
  loading: boolean;
  alreadyInvited: boolean;
}) => {
  const theme = useTheme();
  const loadingSpinnerColor = theme.new.theme === 'light' ? 'blue' : 'white';

  if (loading) {
    return <LoadingSpinner size={'22px'} color={loadingSpinnerColor} />;
  }
  if (alreadyInvited) {
    return (
      <Iconography
        name={AvailableIcons.CANCEL_SEND}
        color={theme.new.error.background}
      />
    );
  }
  return (
    <Iconography
      name={AvailableIcons.SEND}
      color={theme.new.information.background}
    />
  );
};

export const InviteUserRow = ({
  matchData,
  opponent,
  storybookLoading,
  storybookAlreadyInvited = false,
}: InviteUserRowProps) => {
  const { user } = useUser();
  const isInvited =
    Boolean(
      matchData.invites?.find((invite) => invite?.user?.id === opponent.id),
    ) ||
    Boolean(
      matchData.playerQueue?.find((invite) => invite?.user?.id === opponent.id),
    );
  const [invited, setInvited] = useState<boolean>(isInvited);

  const [invite, { loading: inviteLoading }] = useInviteToChallengeMutation({
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setInvited(true);
    },
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error('useInviteToChallengeMutation error', err);
    },
  });

  const [
    cancelInvite,
    { loading: cancelLoading },
  ] = useCancelInviteToChallengeMutation({
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setInvited(false);
    },
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.error('useCancelInviteToChallengeMutation error', err);
    },
  });

  if (opponent.id === user?.id) return null;

  const loadingSend = storybookLoading || inviteLoading || cancelLoading;

  const alreadyInvited = storybookAlreadyInvited || invited;

  const handleInvite = () => {
    if (!matchData.id) return;
    if (!opponent.id) return;

    invite({
      variables: {
        challengeId: matchData.id,
        userId: opponent.id,
      },
    });
  };

  const handleCancelInvite = () => {
    if (!matchData.id) return;
    if (!opponent.id) return;

    cancelInvite({
      variables: {
        challengeId: matchData.id,
        userId: opponent.id,
      },
    });
  };

  return (
    <RowWrapper>
      <LeftItems>
        <Avatar
          size="large"
          user={opponent}
          plr={{
            type: 'tile',
            context: 'challenge',
            gameSeriesId: matchData.game?.gameSeriesId,
          }}
        />
        {opponent.usernameFlairs?.hasPlPlus ? (
          <PlPlusOpponentName>{opponent.username}</PlPlusOpponentName>
        ) : (
          <OpponentName>{opponent.username}</OpponentName>
        )}
      </LeftItems>
      <IconWrapper
        disabled={loadingSend}
        onClick={alreadyInvited ? handleCancelInvite : handleInvite}
      >
        <Icon loading={loadingSend} alreadyInvited={alreadyInvited} />
      </IconWrapper>
    </RowWrapper>
  );
};
