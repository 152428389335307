import React, { useState } from 'react';
import Surface from 'components/atoms/surface';
import {
  AvailableIcons,
  fontL,
  Iconography,
  MIN_DESKTOP,
  TOUCHABLE_STYLE,
} from 'components/styles';
import styled from 'styled-components';
import Chat from 'components/organisms/chat';
import { MatchContestFragment } from 'graphpl/core';
import { Divider } from 'components/atoms';

export const StyledSurface = styled(Surface)<{
  isOpened: boolean;
  playerInvitesPresent: boolean;
}>`
  margin-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
  flex: 1;
  max-height: ${({ isOpened }) => (isOpened ? '550px' : '72px')};
  transition: max-height 600ms ease-in-out, min-height 600ms ease-in-out;
  overflow: hidden;
  min-height: ${({ isOpened }) => (isOpened ? '200px' : '0px')};

  @media (min-width: ${MIN_DESKTOP}) {
    max-height: ${({ isOpened, playerInvitesPresent }) => {
      if (!isOpened) return '72px';
      if (playerInvitesPresent) return '434px';
      return '550px';
    }};
  }
`;

export const HeaderWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  cursor: pointer;

  ${TOUCHABLE_STYLE}
`;

export const SectionHeading = styled.h2`
  ${fontL}
  color: ${({ theme }) => theme.new.content};
`;

export const IconWrapper = styled(Iconography)<{ isOpened: boolean }>`
  transition: transform 300ms ease-in-out;
  transform: ${({ isOpened }) =>
    isOpened ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export const ContentWrapper = styled.div<{ isOpened: boolean }>`
  overflow: hidden;
  flex: 1;
  transition: max-height 600ms ease-in-out;
`;

type MatchChatProps = {
  matchData: MatchContestFragment;
  playerInvitesPresent?: boolean;
  storybookIsOpen?: boolean;
};

export const MatchChat = ({
  matchData,
  storybookIsOpen,
  playerInvitesPresent = false,
}: MatchChatProps) => {
  const [isOpened, setIsOpened] = useState(true);

  return (
    <StyledSurface
      size="large"
      isOpened={isOpened}
      playerInvitesPresent={playerInvitesPresent}
    >
      <HeaderWrap
        role="button"
        data-testid="match-rules-section"
        tabIndex={0}
        onClick={() => setIsOpened((prev) => !prev)}
        onKeyPress={() => setIsOpened((prev) => !prev)}
      >
        <SectionHeading>Chat</SectionHeading>
        <IconWrapper name={AvailableIcons.EXPAND} isOpened={isOpened} />
      </HeaderWrap>

      <ContentWrapper
        isOpened={storybookIsOpen || isOpened}
        data-testid="match-rules-content-wrapper"
      >
        <Divider />

        <Chat
          isOpen
          channelName={`match-${matchData.id}`}
          channelType="global"
          gameSeriesId={matchData?.game?.gameSeriesId || undefined}
        />
      </ContentWrapper>
    </StyledSurface>
  );
};
