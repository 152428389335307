import { CmsTournamentFragment } from 'graphpl/cms';

export const tournamentInfoFunc = (cmsTournaments: CmsTournamentFragment[]) => (
  passedId?: string | number | null,
): CmsTournamentFragment | undefined => {
  if (!cmsTournaments) return;
  if (cmsTournaments.length === 0) return;
  if (!passedId) return;

  return cmsTournaments.find(
    ({ internalId }) => `${internalId}` === `${passedId}`,
  );
};
