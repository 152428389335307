import { ResponsivePill } from 'components/atoms/button/button';
import Surface from 'components/atoms/surface';
import {
  ConsolesFragment,
  MatchContestFragment,
  useAcceptDirectChallengeMutation,
} from 'graphpl/core';
import React, { useState } from 'react';
import TextInput from 'components/atoms/text-input';
import { NewRadio } from 'components/atoms/new-radio';
import { useGlobal } from 'components/util/global-context';
import { useModal } from 'components/util/modal-context';
import { AvailableIcons, Iconography } from 'components/styles';
import { CenterSpinner } from 'components/atoms/loading-spinner';
import AddGamertag, {
  AddGamertagHeader,
  gamertagIsMissing,
  messageMap,
} from 'components/organisms/add-gamertag-modal';
import { useToast } from 'components/util/toast-context';
import MlbPlatformModal, {
  isMLBPlatformError,
} from 'components/organisms/mlb-platform-modal';
import depositNowToast, {
  isLackOfFundsError,
} from 'components/molecules/deposit-now-toast';
import ServiceFee from 'components/molecules/service-fee';
import { useUser } from 'components/util/user-context';
import {
  crossplayConsoles,
  isCrossplayConsole,
  isCrossplayGame,
} from 'components/util/helpers';
import { messageParent } from 'helpers/web-view';
import {
  ContentWrapper,
  HalfSpacing,
  HeaderIconWrapper,
  HeadingText,
  HeadingWrapper,
  SelectorRow,
  SelectorText,
  SelectorWrapper,
  SubHeadingText,
} from './confirm-entry.styles';

type ConfirmEntryProps = {
  matchData: MatchContestFragment;
  storybookLoading?: boolean;
  storybookDisabled?: boolean;
};

type GetHelperMessageProps = {
  amountTooLow: boolean;
  amountTooHigh: boolean;
  amountMin: MatchContestFragment['amountMin'];
  amount: MatchContestFragment['amount'];
};

const getHelperMessage = ({
  amountTooLow,
  amountTooHigh,
  amountMin,
  amount,
}: GetHelperMessageProps) => {
  if (amountTooLow) return `Enter an amount of at least $${amountMin?.value}`;
  if (amountTooHigh)
    return `Enter an amount less than or equal to $${amount?.value}`;
  if (!amountMin?.value || amountMin?.value === amount?.value)
    return `$${amount?.value} is the only available amount`;
  return `Enter an amount between $${amountMin?.value} and $${amount?.value}`;
};

export const ConfirmEntry = ({
  matchData,
  storybookLoading,
  storybookDisabled,
}: ConfirmEntryProps) => {
  const { consoles } = useGlobal();
  const { displayToast, dismissToast } = useToast();
  const { displayModal, dismissModal } = useModal();
  const { user } = useUser();
  const preselectedAmount: number = matchData?.amountMin?.value
    ? matchData.amountMin.value
    : matchData.amount?.value || 1;

  const [amount, setAmount] = useState<string>(`${preselectedAmount}`);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = e.target.value.replace('$', '');
    setAmount(parsedValue);
  };

  const onlyOneFormat = (matchData.allowedGameFormats || [])?.length <= 1;

  const [selectedGameFormat, setSelectedGameFormat] = useState<
    string | undefined
  >(
    onlyOneFormat
      ? matchData.allowedGameFormats?.[0]?.key ||
          matchData.gameFormat?.key ||
          undefined
      : undefined,
  );

  const allowedGameFormats =
    (matchData.allowedGameFormats || [])?.length === 0
      ? [matchData.gameFormat]
      : matchData.allowedGameFormats;

  const changeSelectedGameFormat = (gameFormatKey: string) => {
    if (selectedGameFormat === gameFormatKey) {
      setSelectedGameFormat(undefined);
      return;
    }
    setSelectedGameFormat(gameFormatKey);
  };

  const matchIsCrossplay = isCrossplayGame(matchData?.game?.gameSeriesId || '');
  const matchPlatformIsCrossplay = isCrossplayConsole(
    matchData.consoleSetting?.id || '',
  );

  const platforms = matchData?.game?.consoles
    ?.map((console) => consoles.find((c) => c.id === console))
    .filter((c) => {
      if (matchIsCrossplay && matchPlatformIsCrossplay)
        return crossplayConsoles.includes(c?.id || '');
      return Boolean(c as ConsolesFragment[] | undefined);
    });

  const onlyOnePlatform = platforms?.length === 1;

  const [selectedPlatform, setSelectedPlatform] = useState<string | undefined>(
    onlyOnePlatform ? platforms?.[0]?.id || undefined : undefined,
  );

  const changeSelectedPlatform = (platformId: string) => {
    if (selectedPlatform === platformId) {
      setSelectedPlatform(undefined);
      return;
    }
    setSelectedPlatform(platformId);
  };

  const [
    acceptChallenge,
    {
      loading: acceptChallengeLoading,
      called: acceptChallengeMutationCalled,
      error: acceptChallengeMutationError,
    },
  ] = useAcceptDirectChallengeMutation({
    errorPolicy: 'none',
    onCompleted: () => {
      displayToast({
        toastDisplayed: true,
        type: 'success',
        heading: 'Challenge succefssfully joined!',
        subtext: 'The creator of the Challenge will be notified.',
      });

      dismissModal();
    },
    onError: (error) => {
      if (isMLBPlatformError(error.message)) {
        displayToast({
          toastDisplayed: true,
          type: 'error',
          disableTimeout: true,
          subtext: <MlbPlatformModal onClick={dismissToast} />,
        });
        return;
      }

      if (isLackOfFundsError(error?.message)) {
        depositNowToast(displayToast, dismissToast, dismissModal);
        return;
      }

      if (gamertagIsMissing(error?.message)) {
        messageParent({
          action: 'NAVIGATE',
          source: 'match-creation-modal',
          data: {
            url: '/user/gamertags',
            targetId: messageMap[error.message],
          },
        });
        displayModal({
          type: 'default',
          header: <AddGamertagHeader message={error?.message} />,
          body: <AddGamertag message={error?.message} />,
        });
        return;
      }

      displayToast({
        toastDisplayed: true,
        type: 'warning',
        subtext: error.message,
      });
    },
  });

  const handleSubmit = () => {
    if (!matchData.id) return;
    if (!amount) return;
    if (!selectedGameFormat) return;
    if (!selectedPlatform) return;

    acceptChallenge({
      variables: {
        directChallengeId: matchData.id,
        amount: parseFloat(amount),
        gameFormatId: selectedGameFormat,
        joiningFromConsole: selectedPlatform,
      },
    });
  };

  const placementLoading = storybookLoading || acceptChallengeLoading;

  const amountTooLow =
    parseFloat(amount) <
    (matchData?.amountMin?.value || matchData?.amount?.value || 0);
  const amountTooHigh = parseFloat(amount) > (matchData?.amount?.value || 0);

  const amountDisabled =
    storybookDisabled ||
    placementLoading ||
    !matchData.amountMin ||
    matchData.amountMin?.value === matchData.amount?.value;

  const gameFormatDisabled =
    storybookDisabled || placementLoading || onlyOneFormat;
  const platformDisabled =
    storybookDisabled || placementLoading || onlyOnePlatform;
  const placementDisabled =
    storybookDisabled ||
    placementLoading ||
    !selectedGameFormat ||
    !selectedPlatform ||
    !amount ||
    amountTooLow ||
    amountTooHigh ||
    (acceptChallengeMutationCalled && !acceptChallengeMutationError);
  return (
    <Surface size="large" context="confirmationModal">
      <HeadingWrapper>
        <HeadingText>Confirm entry</HeadingText>
        <HeaderIconWrapper onClick={dismissModal}>
          <Iconography name={AvailableIcons.CROSS} />
        </HeaderIconWrapper>
      </HeadingWrapper>

      <ContentWrapper>
        <TextInput
          id="entry-input"
          label="Entry"
          name="entry"
          pattern="[0-9]*"
          type="number"
          inputmode="numeric"
          onChange={handleAmountChange}
          value={`${amount}`}
          disabled={amountDisabled}
          prefix="$"
          error={amountTooLow || amountTooHigh}
          message={getHelperMessage({
            amountTooLow,
            amountTooHigh,
            amountMin: matchData.amountMin,
            amount: matchData.amount,
          })}
        />
        <HalfSpacing />
        <SubHeadingText>Game mode</SubHeadingText>
        <SelectorWrapper>
          {allowedGameFormats?.map((gameFormat) => {
            if (!gameFormat || !gameFormat.name || !gameFormat.key) return null;

            return (
              <SelectorRow
                key={gameFormat?.name}
                onClick={() => {
                  if (gameFormatDisabled) return;
                  changeSelectedGameFormat(gameFormat.key as string);
                }}
                disabled={gameFormatDisabled}
              >
                <NewRadio
                  checked={
                    gameFormat?.key === selectedGameFormat || onlyOneFormat
                  }
                />
                <SelectorText>{gameFormat?.name}</SelectorText>
              </SelectorRow>
            );
          })}
        </SelectorWrapper>
        {platforms && platforms.length > 1 && (
          <>
            <SubHeadingText>Your platform</SubHeadingText>
            <SelectorWrapper>
              {platforms?.map((platform) => {
                if (!platform || !platform.displayName || !platform.id)
                  return null;

                return (
                  <SelectorRow
                    key={platform.id}
                    onClick={() => {
                      if (platformDisabled) return;
                      changeSelectedPlatform(platform.id || '');
                    }}
                    disabled={platformDisabled}
                  >
                    <NewRadio checked={platform?.id === selectedPlatform} />
                    <SelectorText>{platform?.displayName}</SelectorText>
                  </SelectorRow>
                );
              })}
            </SelectorWrapper>
          </>
        )}
        <HalfSpacing />
        <ServiceFee
          selectedAmounts={[{ value: parseFloat(amount) || 0 }]}
          userHasPlPlus={Boolean(user?.hasPlPlus)}
        />
        <HalfSpacing />
        <ResponsivePill
          size="large"
          purpose="success"
          onClick={handleSubmit}
          disabled={placementDisabled}
        >
          {placementLoading ? (
            <CenterSpinner size={24} color="white" />
          ) : (
            'Confirm'
          )}
        </ResponsivePill>
      </ContentWrapper>
    </Surface>
  );
};
