import React, { useEffect, useState } from 'react';
import { MatchContestFragment, useGetMatchLazyQuery } from 'graphpl/core';
import Surface from 'components/atoms/surface';
import { useModal } from 'components/util/modal-context';
import { AvailableIcons, Iconography } from 'components/styles';
import {
  bindChannel,
  initPusherSocket,
  subscribeToChannel,
  unbindChannel,
} from 'helpers';
import { isValidToShowNewMatchPage } from 'pages/new-match/[matchId]';
import {
  ContentWrapper,
  HeaderIconWrapper,
  HeadingText,
  HeadingWrapper,
  SurfaceWrapper,
} from './players-joined.styles';
import { JoinedUserRow, PendingUserRow } from '../joined-user-row';

type PlayersJoinedProps = {
  matchData: MatchContestFragment;
};

export const PlayersJoined = ({
  matchData: initialMatchData,
}: PlayersJoinedProps) => {
  const [matchData, setMatchData] = useState<MatchContestFragment>(
    initialMatchData,
  );
  const { dismissModal } = useModal();
  const [refectMatcHData] = useGetMatchLazyQuery({
    variables: {
      matchId: matchData?.id || '',
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    onCompleted: (data) => {
      if (!data?.contest?.id) return;
      setMatchData(data.contest);
      if (!isValidToShowNewMatchPage(data?.contest.status)) dismissModal();
    },
  });

  useEffect(() => {
    if (!matchData?.id) return;

    initPusherSocket();

    const channelName = `match-${matchData.id}`;
    const channel = subscribeToChannel(channelName);

    if (!channel) return;

    bindChannel<string>(channel, 'contest-state', () => {
      refectMatcHData();
    });

    bindChannel<string>(channel, 'contest-force-refresh', () => {
      refectMatcHData();
    });

    const cleanup = () => {
      unbindChannel(channel, 'contest-state');
      unbindChannel(channel, 'contest-force-refresh');
    };

    return cleanup;
  }, [matchData?.id]);

  return (
    <Surface size="large" context="confirmationModal">
      <SurfaceWrapper>
        <HeadingWrapper>
          <HeadingText>Potential Players</HeadingText>
          <HeaderIconWrapper onClick={dismissModal}>
            <Iconography name={AvailableIcons.CROSS} />
          </HeaderIconWrapper>
        </HeadingWrapper>

        <ContentWrapper>
          {matchData?.playerQueue?.length > 0 &&
            matchData.playerQueue.map((opponent) => (
              <JoinedUserRow
                matchData={matchData}
                opponent={opponent}
                key={`${opponent?.user?.id} - ${opponent?.joiningFromConsole} - ${opponent?.status} - ${opponent?.proposedAmount} - ${opponent?.proposedFormat}`}
              />
            ))}

          {matchData.invites &&
            matchData.invites.length > 0 &&
            matchData.invites.map((opponent) => {
              if (!opponent?.user) return null;
              return (
                <PendingUserRow
                  matchData={matchData}
                  opponent={opponent.user}
                  key={`${opponent?.user?.id} - ${opponent?.status}`}
                />
              );
            })}
        </ContentWrapper>
      </SurfaceWrapper>
    </Surface>
  );
};
